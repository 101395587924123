/* eslint-disable no-unused-vars */
export const PreActionsSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5484 26.9751H34.9297C35.2109 26.9751 35.4922 27.2001 35.4359 27.5376V33.0501C35.4359 33.3876 35.1547 33.6126 34.8734 33.6126H26.4922C26.1547 33.6126 25.9297 33.3314 25.9297 33.0501V30.9689H19.9672C19.5734 30.9689 19.2922 30.6313 19.2922 30.2938V25.8501H20.6422V29.6188H25.9859V27.5376C25.9859 27.2001 26.2672 26.9751 26.5484 26.9751ZM20.6422 14.0938V9.6501C20.6422 9.3126 20.3609 8.9751 19.9672 8.9751H14.0609V7.00635C14.0609 6.7251 13.8359 6.44385 13.4984 6.44385H5.11719C4.83594 6.44385 4.55469 6.66885 4.55469 7.00635V12.5188C4.55469 12.8001 4.77969 13.0813 5.11719 13.0813H13.4984C13.7797 13.0813 14.0609 12.8563 14.0609 12.5188V10.4376H19.4047C19.4047 10.4376 19.4606 14.1963 19.4047 14.2063L20.6422 14.0938Z"
      fill="#0253B3"
    />
    <path
      d="M6.29908 5.54365C6.41158 5.7124 6.63658 5.88115 6.86158 5.88115C6.97408 5.88115 7.08658 5.8249 7.19908 5.76865C7.36783 5.65615 7.42408 5.54365 7.48033 5.3749C7.53658 5.20615 7.48033 5.0374 7.42408 4.86865L6.41158 3.2374C6.29908 3.06865 6.07408 2.8999 5.84908 2.8999C5.73658 2.8999 5.62408 2.95615 5.51158 3.0124C5.39908 3.1249 5.28658 3.29365 5.23033 3.4624C5.17408 3.63115 5.23033 3.7999 5.28658 3.96865L6.29908 5.54365Z"
      fill="#0253B3"
    />
    <path
      d="M11.4174 5.76855C11.5299 5.8248 11.6424 5.88105 11.7549 5.88105C11.9799 5.88105 12.2049 5.76855 12.3174 5.54355L13.2737 3.96855C13.3862 3.7998 13.3862 3.63105 13.3299 3.4623C13.2737 3.29355 13.1612 3.1248 13.0487 3.06855C12.9362 3.0123 12.8237 2.95605 12.7112 2.95605C12.4862 2.95605 12.2612 3.06855 12.1487 3.29355L11.1362 4.9248C11.0237 5.09355 11.0237 5.2623 11.0799 5.43105C11.1924 5.54355 11.2487 5.65605 11.4174 5.76855Z"
      fill="#0253B3"
    />
    <path
      d="M9.28047 5.4875C9.61797 5.4875 9.95547 5.20625 9.95547 4.8125V2.675C9.95547 2.3375 9.67422 2 9.28047 2C8.94297 2 8.60547 2.28125 8.60547 2.675V4.8125C8.66172 5.15 8.94297 5.4875 9.28047 5.4875Z"
      fill="#0253B3"
    />
    <path
      d="M33.7495 34.4562C33.637 34.2874 33.412 34.1187 33.187 34.1187C33.0745 34.1187 32.962 34.1749 32.8495 34.2312C32.6807 34.3437 32.6245 34.4562 32.5682 34.6249C32.512 34.7937 32.5682 34.9624 32.6245 35.1312L33.637 36.7624C33.7495 36.9312 33.9745 37.0999 34.1995 37.0999C34.312 37.0999 34.4245 37.0437 34.537 36.9874C34.7057 36.8749 34.762 36.7624 34.8182 36.5937C34.8745 36.4249 34.8182 36.2562 34.762 36.0874L33.7495 34.4562Z"
      fill="#0253B3"
    />
    <path
      d="M28.6291 34.2312C28.5166 34.1749 28.4041 34.1187 28.2916 34.1187C28.0666 34.1187 27.8416 34.2312 27.7291 34.4562L26.7729 36.0312C26.6604 36.1999 26.6604 36.3687 26.7166 36.5374C26.7729 36.7062 26.8854 36.8749 26.9979 36.9312C27.1104 36.9874 27.2229 37.0437 27.3354 37.0437C27.5604 37.0437 27.7854 36.9312 27.8979 36.7062L28.9104 35.0749C29.0229 34.9062 29.0229 34.7374 28.9666 34.5687C28.8541 34.4562 28.7979 34.3437 28.6291 34.2312Z"
      fill="#0253B3"
    />
    <path
      d="M30.7668 34.5127C30.4293 34.5127 30.0918 34.7939 30.0918 35.1877V37.3252C30.0918 37.6627 30.373 38.0002 30.7668 38.0002C31.1043 38.0002 31.4418 37.7189 31.4418 37.3252V35.1877C31.3855 34.8502 31.1043 34.5127 30.7668 34.5127Z"
      fill="#0253B3"
    />
    <path
      d="M25 15.6943V14H15V15.6943H19.707V16.3153H19.0855V17H20.9145V16.3153H20.293V15.6943H25Z"
      fill="#0253B3"
    />
    <path
      d="M20.4041 22.0601H19.6953V23.0403H20.4041V22.0601Z"
      fill="#0253B3"
    />
    <path
      d="M20.4041 20.0986H19.6953V21.0789H20.4041V20.0986Z"
      fill="#0253B3"
    />
    <path d="M20.4041 24.02H19.6953V25.0003H20.4041V24.02Z" fill="#0253B3" />
    <path
      d="M20.4041 18.1382H19.6953V19.1184H20.4041V18.1382Z"
      fill="#0253B3"
    />
    <path
      d="M22.3893 21.5237L21.7344 21.7949L22.1095 22.7006L22.7644 22.4293L22.3893 21.5237Z"
      fill="#0253B3"
    />
    <path
      d="M21.6393 19.7126L20.9844 19.9839L21.3595 20.8895L22.0144 20.6183L21.6393 19.7126Z"
      fill="#0253B3"
    />
    <path
      d="M23.1373 23.3352L22.4824 23.6064L22.8576 24.5121L23.5125 24.2408L23.1373 23.3352Z"
      fill="#0253B3"
    />
    <path
      d="M22.6321 18.8821L22.1309 19.3833L22.824 20.0764L23.3252 19.5752L22.6321 18.8821Z"
      fill="#0253B3"
    />
    <path
      d="M24.0188 20.2683L23.5176 20.7695L24.2107 21.4627L24.7119 20.9615L24.0188 20.2683Z"
      fill="#0253B3"
    />
    <path
      d="M25.4035 21.6551L24.9023 22.1562L25.5955 22.8494L26.0967 22.3482L25.4035 21.6551Z"
      fill="#0253B3"
    />
    <path
      d="M21.2492 17.4969L20.748 17.998L21.4412 18.6912L21.9424 18.19L21.2492 17.4969Z"
      fill="#0253B3"
    />
    <path
      d="M17.7072 21.5231L17.332 22.4287L17.9869 22.7L18.3621 21.7943L17.7072 21.5231Z"
      fill="#0253B3"
    />
    <path
      d="M16.9611 23.3351L16.5859 24.2407L17.2408 24.512L17.616 23.6063L16.9611 23.3351Z"
      fill="#0253B3"
    />
    <path
      d="M18.4572 19.712L18.082 20.6177L18.7369 20.8889L19.1121 19.9833L18.4572 19.712Z"
      fill="#0253B3"
    />
    <path
      d="M14.6931 21.655L14 22.3481L14.5012 22.8493L15.1943 22.1562L14.6931 21.655Z"
      fill="#0253B3"
    />
    <path
      d="M16.0798 20.2693L15.3867 20.9624L15.8879 21.4636L16.581 20.7705L16.0798 20.2693Z"
      fill="#0253B3"
    />
    <path
      d="M17.4666 18.8831L16.7734 19.5762L17.2746 20.0774L17.9678 19.3842L17.4666 18.8831Z"
      fill="#0253B3"
    />
    <path
      d="M18.8513 17.4968L18.1582 18.1899L18.6594 18.6911L19.3525 17.998L18.8513 17.4968Z"
      fill="#0253B3"
    />
  </svg>
);
