/* eslint-disable no-unused-vars */
export const GasElectric = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M15.5425 25.8268C16.8054 25.8268 17.8292 24.803 17.8292 23.5401C17.8292 22.2772 16.8054 21.2534 15.5425 21.2534C14.2796 21.2534 13.2559 22.2772 13.2559 23.5401C13.2559 24.803 14.2796 25.8268 15.5425 25.8268Z"
      fill="#0253B3"
    />
    <path
      d="M21.7539 13.6531V22.1331C21.7539 23.7398 22.6939 25.1798 24.1606 25.8331H24.6139V9.95312H24.1606C22.6939 10.6065 21.7539 12.0465 21.7539 13.6531Z"
      fill="#0253B3"
    />
    <path
      d="M9.34128 22.1271V13.6538C9.34128 12.0404 8.38794 10.6071 6.91461 9.96045H6.47461V25.8338H6.92128C8.39461 25.1805 9.34128 23.7405 9.34128 22.1271Z"
      fill="#0253B3"
    />
    <path
      d="M24.6079 27.4801V26.9734H24.0413C23.9679 26.9734 23.8946 26.9534 23.8279 26.9268C21.8746 26.1268 20.6146 24.2468 20.6146 22.1334V13.6534C20.6146 11.5468 21.8746 9.66677 23.8279 8.86677C23.8946 8.8401 23.9679 8.8201 24.0413 8.8201H24.6079V7.74677C24.6079 6.6601 23.7279 5.77344 22.6346 5.77344H8.44794C7.36128 5.77344 6.47461 6.6601 6.47461 7.74677V8.8201H7.04128C7.08794 8.8201 7.12794 8.8401 7.16794 8.84677C7.20128 8.85344 7.22794 8.85344 7.26128 8.86677H7.26794C7.26794 8.86677 7.27461 8.86677 7.27461 8.87344C9.22128 9.67344 10.4813 11.5534 10.4813 13.6534V22.1334C10.4813 24.2068 9.26128 26.0601 7.36794 26.8801C7.33461 26.9001 7.30128 26.9134 7.26794 26.9334C7.20128 26.9601 7.12794 26.9801 7.05461 26.9801H6.47461V27.4868C6.47461 28.5734 7.36128 29.4601 8.44794 29.4601H22.6346C23.7279 29.4534 24.6079 28.5668 24.6079 27.4801ZM14.9079 12.3601H16.1813C16.4946 12.3601 16.7479 12.6134 16.7479 12.9268C16.7479 13.2401 16.4946 13.4934 16.1813 13.4934H14.9079C14.5946 13.4934 14.3413 13.2401 14.3413 12.9268C14.3413 12.6068 14.5946 12.3601 14.9079 12.3601ZM14.9079 14.7068H16.1813C16.4946 14.7068 16.7479 14.9601 16.7479 15.2734C16.7479 15.5934 16.4946 15.8401 16.1813 15.8401H14.9079C14.5946 15.8401 14.3413 15.5868 14.3413 15.2734C14.3413 14.9601 14.5946 14.7068 14.9079 14.7068ZM14.9079 17.0534H16.1813C16.4946 17.0534 16.7479 17.3068 16.7479 17.6201C16.7479 17.9334 16.4946 18.1868 16.1813 18.1868H14.9079C14.5946 18.1868 14.3413 17.9334 14.3413 17.6201C14.3413 17.3068 14.5946 17.0534 14.9079 17.0534ZM15.5479 26.9668C13.6546 26.9668 12.1213 25.4268 12.1213 23.5334C12.1213 21.6401 13.6613 20.1068 15.5479 20.1068C17.4346 20.1068 18.9746 21.6468 18.9746 23.5334C18.9746 25.4201 17.4346 26.9668 15.5479 26.9668Z"
      fill="#0253B3"
    />
    <path
      d="M32.6415 12.6802C32.0681 12.1268 31.3015 11.8402 30.5148 11.8802C29.5948 11.9202 28.7415 12.4402 28.2015 13.2735C27.7215 14.0268 27.6148 14.7935 27.8748 15.6202C27.8815 15.6535 27.8881 15.6868 27.8948 15.7202L28.7148 18.1002C28.8881 18.5935 28.9681 19.1068 28.9681 19.6335V21.2935C28.9681 22.0068 29.4215 22.6202 30.0615 22.8468V33.1002H20.7681V32.2735H21.9948C22.3081 32.2735 22.5615 32.0202 22.5615 31.7068V30.5935H17.8281V31.7068C17.8281 32.0268 18.0815 32.2735 18.3948 32.2735H19.6215V33.6668C19.6215 33.9802 19.8748 34.2335 20.1881 34.2335H30.6281C30.9415 34.2335 31.1948 33.9802 31.1948 33.6668V22.8402C31.8281 22.6068 32.2815 22.0002 32.2815 21.2868V19.6268C32.2815 19.1002 32.3681 18.5935 32.5348 18.0935L33.3548 15.7135C33.3681 15.6868 33.3748 15.6535 33.3748 15.6202C33.4681 15.3268 33.5148 15.0468 33.5148 14.7668C33.5281 13.9668 33.2148 13.2268 32.6415 12.6802Z"
      fill="#0253B3"
    />
    <path
      d="M11.4556 33.6671V32.2738H12.6823C12.9956 32.2738 13.249 32.0204 13.249 31.7071V30.5938H8.51562V31.7071C8.51562 32.0271 8.76896 32.2738 9.08229 32.2738H10.309V33.6671C10.309 33.9804 10.5623 34.2337 10.8756 34.2337C11.209 34.2337 11.4556 33.9804 11.4556 33.6671Z"
      fill="#0253B3"
    />
  </svg>
);
