import * as React from "react";
export const SprinklersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}
  >
    <path
      fill="#E02029"
      d="M8.002 39.91C8.002 22.242 22.244 8 40.092 8c17.668 0 31.91 14.423 31.91 32.09 0 17.668-14.423 31.91-32.09 31.91-17.848 0-32.09-14.422-31.91-32.09Zm32.27 15.865c0-.36 0-.721-.36-.721-.361.18-.361.36-.18.72-.361 2.525-1.623 4.688-1.443 7.212 1.803 1.442 1.803 1.442 3.606-.18 0-2.344-1.262-4.688-1.623-7.031Zm9.195-1.443c0-.18 0-.36-.18-.36-.181.36 0 .54.18.36.36 2.164 1.442 4.147 2.343 6.13.541 1.262 1.803 1.262 2.885.54 1.081-.72 1.262-1.622.36-2.523-1.803-1.262-3.425-3.065-5.588-4.147ZM45.32 16.834H34.503c-.54 1.442.18 2.884-.54 4.507h-4.868c.36 7.932-1.262 16.045 4.146 23.076-1.262.36-2.343-.18-3.425.36v2.705h20.191c0-1.082.36-1.983-.36-3.065h-3.065c5.228-7.211 3.606-15.144 4.146-23.256H45.32v-4.327ZM24.047 59.56c.18 1.442 1.081 1.983 2.163 1.983 1.082.18 1.623-.721 1.983-1.623a77.401 77.401 0 0 1 2.344-5.769c-2.344 1.803-5.048 3.065-6.49 5.409Zm8.113-1.803c2.163 1.262 3.785.901 3.966-.721.18-2.344 0-4.507 0-6.851-1.443 2.524-3.786 4.507-3.966 7.572Zm15.684-.18c-.36-2.705-2.344-4.868-3.786-7.392-.18 2.524-.36 5.048 0 7.572 1.262 1.262 2.524 1.262 3.786-.18Z"
    />
    <path
      fill="#F09396"
      d="M40.264 55.775h-.721c0-.36 0-.54.18-.72.541 0 .721.36.541.72Z"
    />
    <path
      fill="#FAFBFB"
      d="M49.464 54.337c-.182 0-.364 0-.182-.363.182.182.182.182.182.363Z"
    />
    <path
      fill="#E32329"
      d="M42.44 41.714c-1.08-4.868-.18-9.375-.54-13.882 1.262-1.082 3.245-.721 3.966-2.344 1.623 4.147-.18 13.521-3.425 16.226ZM36.667 41.355c-3.966-4.507-2.885-10.276-3.245-16.045 1.442 1.442 2.884 1.622 4.146 2.163v13.701c-.36.18-.54.18-.901.18Z"
    />
  </svg>
);
