/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
import Banner from "../../assets/img/men-working-with-equipment-full-shot.png";
import { Helmet } from "react-helmet-async";

import cardImg1 from "../../assets/img/card1.png";
import cardImg1_1 from "../../assets/img/card1-1.png";
import cardImg1_2 from "../../assets/img/card1-2.jpg";
import cardImg1_3 from "../../assets/img/card1-3.jpg";
import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: cardImg1 },
  { url: cardImg1_1 },
  { url: cardImg1_2 },
  { url: cardImg1_3 },
];

export const NYCSCASchoolPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>New York School at Queens | Vital Plumbing Projects</title>
        <meta
          name="description"
          content="Check out our work at NY School in Queens. Your plumbing and HVAC solution in NY. Call us for a free quote. "
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            NYCSCA PS 129Q NEW SCHOOL
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Owner:</p>
                  <p className="text-xl">
                    New York City School Construction Authority
                  </p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Architect:
                  </p>
                  <p className="text-xl">Kenny & Kahn Architects P.C.</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Location:
                  </p>
                  <p className="text-xl">Queens, New York</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Completion:
                  </p>
                  <p className="text-xl">2021</p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  The Project of PS 129Q is located in College Point, Queens New
                  York and is a 52,000 sft New Construction. The Construction
                  Management is performed by the New York City School
                  Construction Authority and the design is by Kenny & Khan
                  Architects PC. Concrete on deck structural steel
                  superstructure, brick and block exterior envelope, aluminum
                  windows and storefronts, a new elevator, new independent HVAC
                  systems with three new RTUs. New standalone gas, electric,
                  sewer and water services. Fire protection systems, fire alarm,
                  intrusion alarm will also be installed and commissioned. The
                  project is under the NYC Green Schools Guide and Rating System
                  and will achieve compliance with the Local Law 86 of 2005.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
