/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
// import Banner from "../../assets/img/projects2-banner.png";
import { Helmet } from "react-helmet-async";

import cardImg3 from "../../assets/img/card3.png";
import cardImg3_2 from "../../assets/img/card3-2.png";
import cardImg3_3 from "../../assets/img/card3-3.png";
import cardImg3_4 from "../../assets/img/card3-4.png";

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: cardImg3 },
  { url: cardImg3_2 },
  { url: cardImg3_3 },
  { url: cardImg3_4 },
];

export const PsTrinityPlace = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Plumbing for school in Manhatten | Vital Plumbing Projects
        </title>
        <meta
          name="description"
          content="Check out our plumbing work at school in Manhattan. Your plumbing and HVAC solution in NY. Call us for a free quote."
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${cardImg3})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            PS 464M TRINITY PLACE
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Location:</p>
                  <p className="text-xl">Manhattan, NY</p>
                  <p className="text-base text-grey-1 font-light">Sector:</p>
                  <p className="text-xl">Education</p>
                  <p className="text-base text-grey-1 font-light mt-4">Size:</p>
                  <p className="text-xl">90,000 SQ FT</p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  The new PS 464 is located in a 38-story mixed development on
                  Trinity Place in Manhattan, NY. The new 90,000 SF school
                  occupies nine development floors and will include new
                  classrooms, a gymnasium, cafeteria, library, multipurpose
                  room, (2) passenger elevators, and a rooftop play area.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
