/* eslint-disable no-unused-vars */
export const CureFDNY = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M12.4706 19.1216C7.79991 19.1216 4 22.9215 4 27.5922C4 32.2629 7.79991 36.0628 12.4706 36.0628C17.1413 36.0628 20.9412 32.2629 20.9412 27.5922C20.9412 22.9215 17.1413 19.1216 12.4706 19.1216ZM16.2106 26.5635L11.8184 30.9557C11.6347 31.1395 11.3938 31.2314 11.1529 31.2314C10.9121 31.2314 10.6712 31.1395 10.4875 30.9557L8.60511 29.0733C8.23755 28.7058 8.23755 28.1099 8.60511 27.7423C8.97261 27.3748 9.56857 27.3748 9.93613 27.7423L11.1529 28.9592L14.8796 25.2325C15.2471 24.865 15.8431 24.865 16.2106 25.2325C16.5781 25.6001 16.5781 26.196 16.2106 26.5635Z"
      fill="#0253B3"
    />
    <path
      d="M28.7842 12.0939C28.2644 12.0939 27.843 11.6725 27.843 11.1527V3.93701H14.3528C12.7959 3.93701 11.5293 5.20365 11.5293 6.76054V17.2822C11.8394 17.2541 12.1532 17.239 12.4705 17.239C15.6785 17.239 18.5503 18.7057 20.4508 21.0037H30.6666C31.1863 21.0037 31.6077 21.4251 31.6077 21.9449C31.6077 22.4646 31.1863 22.886 30.6666 22.886H21.6907C22.279 24.034 22.6584 25.3058 22.7802 26.6507H30.6666C31.1863 26.6507 31.6077 27.0721 31.6077 27.5919C31.6077 28.1117 31.1863 28.5331 30.6666 28.5331H22.7802C22.4987 31.6414 20.838 34.3575 18.4161 36.0625H33.1764C34.7333 36.0625 35.9999 34.7959 35.9999 33.239V12.0939H28.7842ZM30.6666 17.239H16.8626C16.3429 17.239 15.9215 16.8176 15.9215 16.2978C15.9215 15.778 16.3429 15.3566 16.8626 15.3566H30.6666C31.1863 15.3566 31.6077 15.778 31.6077 16.2978C31.6077 16.8176 31.1863 17.239 30.6666 17.239Z"
      fill="#0253B3"
    />
    <path
      d="M29.7246 4.48828V10.2117H35.4477L29.7246 4.48828Z"
      fill="#0253B3"
    />
  </svg>
);
