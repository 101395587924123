import { Card } from "../Card";
// import Lightbox from "yet-another-react-lightbox";
import cardImg1 from "../../assets/img/card1.png";
// import cardImg1_1 from "../../assets/img/card1-1.png";
// import cardImg1_2 from "../../assets/img/card1-2.jpg";
// import cardImg1_3 from "../../assets/img/card1-3.jpg";

import cardImg2 from "../../assets/img/card2.png";
// import cardImg2_2 from "../../assets/img/card2-2.png";
// import cardImg2_3 from "../../assets/img/card2-3.png";
// import cardImg2_4 from "../../assets/img/card2-4.jpg";
// import cardImg2_5 from "../../assets/img/card2-5.jpg";
// import cardImg2_6 from "../../assets/img/card2-6.jpg";

import cardImg3 from "../../assets/img/card3.png";
// import cardImg3_2 from "../../assets/img/card3-2.png";
// import cardImg3_3 from "../../assets/img/card3-3.png";
// import cardImg3_4 from "../../assets/img/card3-4.png";

import cardImg4 from "../../assets/img/card4.png";
// import cardImg4_2 from "../../assets/img/card4-2.png";
// import cardImg4_3 from "../../assets/img/card4-3.png";
// import cardImg4_4 from "../../assets/img/card4-4.png";

import cardImg5 from "../../assets/img/card5.png";
import cardImg6 from "../../assets/img/card6.png";
// import cardImg6_2 from "../../assets/img/card6-2.jpg";
// import cardImg6_3 from "../../assets/img/card6-3.jpg";

// import { useState } from "react";
// import "yet-another-react-lightbox/styles.css";
import BackgroundImage from "../../assets/img/back.png";
import { useNavigate } from "react-router-dom";

// const slides1 = [
//   { id: 0, src: cardImg1 },
//   { id: 1, src: cardImg1_1 },
//   { id: 2, src: cardImg1_2 },
//   { id: 3, src: cardImg1_3 },
// ];

// const slides2 = [
//   { id: 0, src: cardImg2 },
//   { id: 1, src: cardImg2_2 },
//   { id: 2, src: cardImg2_3 },
//   { id: 3, src: cardImg2_4 },
//   { id: 4, src: cardImg2_5 },
//   { id: 5, src: cardImg2_6 },
// ];

// const slides3 = [
//   { id: 0, src: cardImg3 },
//   { id: 1, src: cardImg3_2 },
//   { id: 2, src: cardImg3_3 },
//   { id: 4, src: cardImg3_4 },
// ];

// const slides4 = [
//   { id: 0, src: cardImg4 },
//   { id: 1, src: cardImg4_2 },
//   { id: 2, src: cardImg4_3 },
//   { id: 4, src: cardImg4_4 },
// ];

// const slides5 = [{ id: 0, src: cardImg5 }];
// const slides6 = [
//   { id: 0, src: cardImg6 },
//   { id: 1, src: cardImg6_2 },
//   { id: 2, src: cardImg6_3 },
// ];
// const slides = [
//   { id: 0, src: cardImg1 },
//   { id: 1, src: cardImg2 },
//   { id: 2, src: cardImg3 },
//   { id: 3, src: cardImg4 },
//   { id: 4, src: cardImg5 },
//   { id: 5, src: cardImg6 },
// ];

export const OurProjects = () => {
  // const [index, setIndex] = useState(-1);
  // const [category, setCategory] = useState();

  const navigate = useNavigate();

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {/* <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={category}
      /> */}
      <div className="mx-auto max-w-7xl px-5 py-32" id="projects">
        <h2 className="text-white font-bold text-4xl mb-12">OUR PROJECTS</h2>
        <div className="flex flex-wrap items-stretch gap-6 flex-col md:flex-row ">
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides1);
              // setIndex(0);
              navigate("/nycsca-ps-129q-new-school");
            }}
          >
            <img src={cardImg1} alt="NYCSCA PS 129Q NEW SCHOOL" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                NYCSCA PS 129Q NEW SCHOOL
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col  shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides2);
              // setIndex(0);
              navigate("/nycsca-ps-19x-new-school");
            }}
          >
            <img src={cardImg2} alt="NYCSCA PS 19X NEW SCHOOL" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                NYCSCA PS 19X NEW SCHOOL
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col  shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides3);
              // setIndex(0);
              navigate("/ps-464m-trinity-place");
            }}
          >
            <img src={cardImg3} alt="PS 464M TRINITY PLACE" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                PS 464M TRINITY PLACE
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
        </div>
        <div className="flex flex-wrap items-stretch gap-6 flex-col md:flex-row mt-8">
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides4);
              // setIndex(0);
              navigate("/rescue-company-2");
            }}
          >
            <img src={cardImg4} alt="NO NAME" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                RESCUE COMPANY 2
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col  shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides5);
              // setIndex(0);
              navigate("/ps-575-academy-of-american-studies");
            }}
          >
            <img src={cardImg5} alt="P.S. 575 ACADEMY OF AMERICAN STUDIES" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                P.S. 575 ACADEMY OF AMERICAN STUDIES
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
          <Card
            variant="moving-blue-shadow"
            className="bg-white flex items-center flex-col  shadow-card p-0 cursor-pointer"
            onClick={() => {
              // setCategory(slides6);
              // setIndex(0);
              navigate("/red-hook-houses");
            }}
          >
            <img src={cardImg6} alt="RED HOOK HOUSES" />
            <div className="px-5 pt-7 pb-10 w-full">
              <h2 className="text-2xl font-bold text-primary text-left">
                RED HOOK HOUSES
              </h2>
              <p className="text-blue-2 mt-2 font-light text-left">
                Learn more
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
