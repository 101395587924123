/* eslint-disable no-unused-vars */
export const CirculatingPumps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M21.1491 12.8447H16.4654C15.532 13.2968 14.7482 14.0103 14.209 14.8894H23.4056C22.8663 14.0103 22.0825 13.2968 21.1491 12.8447Z"
      fill="#0253B3"
    />
    <path
      d="M24.097 16.6777H13.5126C13.4487 17.009 13.4141 17.3505 13.4141 17.7001C13.4141 18.0496 13.4487 18.3912 13.5126 18.7224H24.097C24.1608 18.3912 24.1955 18.0496 24.1955 17.7001C24.1955 17.3505 24.1608 17.0089 24.097 16.6777Z"
      fill="#0253B3"
    />
    <path
      d="M14.209 20.5103C14.7482 21.3894 15.532 22.1029 16.4653 22.5549H21.1493C22.0827 22.1029 22.8665 21.3893 23.4056 20.5103H14.209Z"
      fill="#0253B3"
    />
    <path
      d="M36.4317 9.05165C35.9129 9.05165 35.4854 9.43845 35.4193 9.93921H31.311V8.38307C31.311 6.77943 30.0063 5.4748 28.4027 5.4748H25.7769C25.2123 5.4748 24.7546 5.93248 24.7546 6.49711V7.94788H23.7068C23.6904 7.87992 23.6669 7.81258 23.6356 7.74695L23.1613 6.75326C22.6529 5.6882 21.5618 5 20.3816 5H17.2384C16.0583 5 14.9672 5.6882 14.4588 6.75319L13.9844 7.74695C13.9531 7.81258 13.9297 7.87992 13.9132 7.94788H12.8654V6.49705C12.8654 5.93242 12.4077 5.47473 11.8431 5.47473H9.21748C7.61384 5.47473 6.30921 6.77936 6.30921 8.38301V14.8705C6.30921 15.3134 6.59438 15.7059 7.01567 15.8427L8.36809 16.2821V20.1698L7.01567 20.6091C6.59445 20.746 6.30921 21.1384 6.30921 21.5814V25.4808C4.19948 25.7274 2.55664 27.5248 2.55664 29.6996V30.7508C2.55664 33.0936 4.46264 34.9996 6.8054 34.9996H36.4296C36.9943 34.9996 37.452 34.5419 37.452 33.9773C37.452 33.4127 36.9942 32.955 36.4296 32.955H6.8054C5.59006 32.955 4.60128 31.9662 4.60128 30.7508V29.6996C4.60128 28.6549 5.33208 27.7783 6.30914 27.5525V28.0687C6.30914 29.6724 7.61377 30.9771 9.21742 30.9771H11.8432C12.2975 30.9771 12.682 30.6806 12.8152 30.2707H24.8049C24.9381 30.6805 25.3227 30.9771 25.7769 30.9771H28.4027C30.0063 30.9771 31.311 29.6724 31.311 28.0687V20.4232H35.4193C35.4854 20.9239 35.9129 21.3108 36.4317 21.3108C36.9963 21.3108 37.454 20.8531 37.454 20.2885V19.4009V10.9615V10.074C37.454 9.50934 36.9963 9.05165 36.4317 9.05165ZM16.3041 7.63387C16.475 7.2759 16.8418 7.04457 17.2385 7.04457H20.3817C20.7784 7.04457 21.1452 7.2759 21.3161 7.63394L21.4659 7.94781H16.1542L16.3041 7.63387ZM24.7547 28.226H12.8656V27.729H24.7547V28.226ZM18.8101 24.454C15.086 24.454 12.0562 21.4242 12.0562 17.7001C12.0562 13.9761 15.086 10.9463 18.8101 10.9463C22.5341 10.9463 25.5639 13.9761 25.5639 17.7001C25.5639 21.4242 22.5341 24.454 18.8101 24.454ZM36.0452 17.2579H31.311V15.8948H36.0452C36.4215 15.8948 36.7267 16.2 36.7267 16.5763C36.7267 16.9527 36.4215 17.2579 36.0452 17.2579Z"
      fill="#0253B3"
    />
  </svg>
);
