/* eslint-disable no-unused-vars */
export const GreaseTrap = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M34.6687 10.4033H16.0362C15.506 10.4033 15.0762 10.8331 15.0762 11.3633V11.7638C15.0762 12.294 15.506 12.7238 16.0362 12.7238H34.6687C35.1989 12.7238 35.6287 12.294 35.6287 11.7638V11.3633C35.6287 10.8331 35.1989 10.4033 34.6687 10.4033Z"
      fill="#0253B3"
    />
    <path
      d="M34.6687 32.6792H16.0362C15.506 32.6792 15.0762 33.109 15.0762 33.6392V34.0396C15.0762 34.5698 15.506 34.9996 16.0362 34.9996H34.6687C35.1989 34.9996 35.6287 34.5698 35.6287 34.0396V33.6392C35.6287 33.109 35.1989 32.6792 34.6687 32.6792Z"
      fill="#0253B3"
    />
    <path
      d="M33.6761 13.5356H17.0352V31.8672H33.6761V13.5356ZM28.3026 27.3164H22.4086C20.911 27.3164 20.0345 25.6291 20.8957 24.4039L23.8427 20.2126C24.0133 19.9698 24.2398 19.7716 24.5031 19.6348C24.7665 19.498 25.0589 19.4265 25.3556 19.4265C25.6524 19.4265 25.9447 19.498 26.2081 19.6348C26.4714 19.7716 26.6979 19.9698 26.8685 20.2126L29.8155 24.4039C30.6767 25.6291 29.7976 27.3164 28.3026 27.3164Z"
      fill="#0253B3"
    />
    <path
      d="M20.8824 6.19337H29.8274C30.3291 6.1939 30.81 6.39342 31.1648 6.74815C31.5195 7.10289 31.719 7.58386 31.7195 8.08553V9.57459H32.9129V6.75956C32.9122 6.29311 32.7266 5.84597 32.3968 5.51614C32.067 5.18631 31.6198 5.0007 31.1534 5H19.5564C19.09 5.0007 18.6428 5.18631 18.313 5.51614C17.9832 5.84597 17.7976 6.29311 17.7969 6.75956V9.57459H18.9902V8.08553C18.9908 7.58386 19.1903 7.10289 19.545 6.74815C19.8998 6.39342 20.3807 6.1939 20.8824 6.19337Z"
      fill="#0253B3"
    />
    <path
      d="M16.6707 14.6632H12.118C11.8484 14.6632 11.5899 14.7703 11.3993 14.9609C11.2087 15.1515 11.1016 15.41 11.1016 15.6796V28.3538C11.1016 28.5385 11.0283 28.7155 10.8977 28.8461C10.7672 28.9766 10.5901 29.05 10.4055 29.05H7.52151C7.36326 29.05 7.21149 28.9871 7.09959 28.8752C6.98769 28.7633 6.92482 28.6115 6.92482 28.4533V12.3428C6.80165 12.3869 6.67178 12.4093 6.54096 12.4091H5.51863C5.38781 12.4093 5.25794 12.3869 5.13477 12.3428V29.89C5.13494 30.1419 5.23509 30.3834 5.41322 30.5616C5.59136 30.7397 5.83291 30.8399 6.08482 30.84H11.9748C12.218 30.84 12.4512 30.7434 12.6231 30.5715C12.7951 30.3995 12.8917 30.1663 12.8917 29.9231V17.0281C12.8917 16.8756 12.9522 16.7294 13.06 16.6216C13.1678 16.5138 13.314 16.4533 13.4665 16.4533H16.6707V14.6632Z"
      fill="#0253B3"
    />
    <path
      d="M6.53972 6.64111H5.51739C4.88431 6.64111 4.37109 7.15433 4.37109 7.78741V11.2628C4.37109 11.8959 4.88431 12.4091 5.51739 12.4091H6.53972C7.1728 12.4091 7.68602 11.8959 7.68602 11.2628V7.78741C7.68602 7.15433 7.1728 6.64111 6.53972 6.64111Z"
      fill="#0253B3"
    />
    <path
      d="M13.3175 9.49187H10.9095C10.804 9.49187 10.7028 9.44996 10.6282 9.37536C10.5536 9.30076 10.5117 9.19958 10.5117 9.09408C10.5117 8.98858 10.5536 8.8874 10.6282 8.8128C10.7028 8.7382 10.804 8.69629 10.9095 8.69629H13.3175C13.423 8.69629 13.5241 8.7382 13.5987 8.8128C13.6733 8.8874 13.7153 8.98858 13.7153 9.09408C13.7153 9.19958 13.6733 9.30076 13.5987 9.37536C13.5241 9.44996 13.423 9.49187 13.3175 9.49187Z"
      fill="#0253B3"
    />
    <path
      d="M9.51592 7.87209C9.43743 7.87189 9.36076 7.84847 9.29555 7.80479C9.23034 7.76111 9.17951 7.69911 9.14945 7.62661C9.1194 7.5541 9.11147 7.47432 9.12666 7.39731C9.14185 7.32031 9.17948 7.24952 9.23482 7.19385L10.9281 5.49728C11.0026 5.42255 11.1038 5.3805 11.2094 5.38037C11.3149 5.38025 11.4162 5.42206 11.491 5.49661C11.5657 5.57117 11.6077 5.67236 11.6079 5.77791C11.608 5.88347 11.5662 5.98476 11.4916 6.05949L9.79769 7.7554C9.76071 7.79243 9.71678 7.8218 9.66843 7.84182C9.62008 7.86184 9.56826 7.87213 9.51592 7.87209Z"
      fill="#0253B3"
    />
    <path
      d="M11.2107 12.8064C11.1583 12.8065 11.1065 12.7962 11.0582 12.7762C11.0098 12.7562 10.9659 12.7268 10.9289 12.6898L9.23563 10.9932C9.1966 10.9567 9.1653 10.9128 9.14362 10.8639C9.12194 10.8151 9.11031 10.7624 9.10943 10.709C9.10855 10.6556 9.11844 10.6026 9.1385 10.5531C9.15857 10.5036 9.1884 10.4586 9.22622 10.4209C9.26404 10.3831 9.30906 10.3534 9.35862 10.3335C9.40817 10.3135 9.46124 10.3038 9.51465 10.3048C9.56806 10.3058 9.62072 10.3175 9.66949 10.3393C9.71826 10.3611 9.76214 10.3925 9.79851 10.4316L11.4924 12.1275C11.548 12.1832 11.5859 12.2541 11.6012 12.3313C11.6165 12.4085 11.6085 12.4885 11.5784 12.5611C11.5482 12.6338 11.4972 12.6959 11.4317 12.7396C11.3663 12.7832 11.2893 12.8065 11.2107 12.8064Z"
      fill="#0253B3"
    />
  </svg>
);
