/* eslint-disable react/prop-types */
import faqBack from "../../assets/img/faqBack.png";
import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const FAQ = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div
      className={`pb-20 `}
      style={{ backgroundImage: `url(${faqBack})` }}
      id="faq"
    >
      <div className="mx-auto max-w-4xl px-5 py-12 md:py-32 text-white text-lg ">
        <h2 className="text-white font-bold text-4xl mb-10">FAQ</h2>
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(1)} className="py-4">
            What are the most common plumbing problems?
          </AccordionHeader>
          <AccordionBody>
            Plumbing problems are sure to happen. Even in newer homes, clogged
            drains or a water leak can show when you least expect it. Whether
            you’re living through the repairs or an older home, or just
            purchasing a new home and want to be prepared, these are the most
            common plumbing problems:
            <ul className="list-disc list-outside	p-5">
              <li>Clogged toilet</li>
              <li>Clogged bath or shower drain</li>
              <li>Jammed garbage disposal</li>
              <li>Leaky faucets</li>
              <li>Leaky pipes</li>
              <li>Low water pressure</li>
              <li>Running toilet</li>
              <li>Sewage smell in home</li>
              <li>Sewer system backup</li>
              <li>Slow draining sink</li>
              <li>Water heater repair</li>
            </ul>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(2)}>
            Why you should never unclog your drains without a plumber
          </AccordionHeader>
          <AccordionBody>
            When you’re dealing with minor clogs, it’s possible that you are
            thinking about how DIY remedy will work to clear your pipes.
            However, do-it-yourself methods of drain cleaning are never a
            completely viable solution to your plumbing problems. DIY methods of
            drain cleaning like drain snaking your drain with a wire hanger and
            pouring harsh chemicals down your drain never come without
            consequence. Your drains are an essential part of your property that
            should be taken care of with precision and accuracy. Using at-home
            methods of drain cleaning involve unnecessary guesswork that only
            temporarily mask your plumbing problems instead of solving them all
            together. Snaking your drain on your own only reaches surface clogs
            and blockages and not deeper ones within your pipe that could be
            causing the actual problems. In addition, chemical drain cleaner use
            toxic ingredients that create a slime and sludge within your pipes
            that can stay within your pipe for long periods of time. Instead of
            trying to fix your plumbing problems on your own, call your team of
            local experts at Cole Plumbing today.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(3)}>
            How do I avoid clogged pipes?
          </AccordionHeader>
          <AccordionBody>
            Clogged pipes can be a major inconvenience. Whether it’s a
            slow-draining tub or a severe blockage in your main sewer line,
            addressing the problem immediately will help prevent further damage.
            The best way to prevent clogged pipes is to be mindful of what you
            put down your drains and toilet. Use these tips as a guide:
            <ul className="list-disc list-outside	p-5">
              <li>Run cold water when using the garbage disposal</li>
              <li>
                A drain strainer should be used if there is no garbage disposal
              </li>
              <li> Avoid pouring grease or oil down the drain</li>
              <li> After washing dishes, run hot water to rinse away grease</li>
              <li> Clear drain stoppers in shower and bathtub</li>
              <li> Only flush waste and toilet tissue down toilet</li>
              <li> Pump septic tank every two to three years</li>
              <li>
                Avoid coffee grounds, bones, grease, fruit peels and other
                fibrous foods in garbage disposal
              </li>
            </ul>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(4)}>
            Do I have a water leak?
          </AccordionHeader>
          <AccordionBody>
            Detecting a water leak can be difficult if your home is built on a
            slab or your pipes run underground. The most common signs of a water
            leak include:
            <ul className="list-disc list-outside	p-5">
              <li>Damp or spongy flooring</li>
              <li>Unexpected Increase in monthly water bill</li>
              <li>Sound of running water coming from walls</li>
              <li>Loss in water pressure</li>
              <li>Grass is greener in one part of yard</li>
            </ul>
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(5)}>
            How long do water heaters last?
          </AccordionHeader>
          <AccordionBody>
            The typical water heater lasts 8 to 12 years. Routine maintenance
            will help extend the life of your unit. If you have a traditional
            tank, draining the water heater once yearly will help rid the unit
            of sediment and minerals that can settle at the bottom. If your
            water heater isn’t advanced in age and is showing signs of trouble,
            call a water heater repair technician in Montgomery to get your unit
            running efficiently.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
          <AccordionHeader onClick={() => handleOpen(6)}>
            How do I prevent pipes from freezing?
          </AccordionHeader>
          <AccordionBody>
            Prevent frozen pipes by following these pipe insulation steps:
            <ul className="list-decimal list-outside	p-5">
              <li>
                Run heating cable along the pipes before covering with
                insulation. The cable will keep the pipes at a constant
                temperature.
              </li>
              <li>
                Inspect the outside of your home looking for visible cracks near
                the foundation or basement. Use spray foam or caulking to fill
                cracks.
              </li>
              <li>
                Shut off the water valves to your outdoor faucets. Allow excess
                water to drain then cover the faucets with an insulated dome to
                prevent freezing.
              </li>
              <li>
                Allow the warm air in your home to reach the pipes beneath your
                sinks by opening the cabinet doors.
              </li>
              <li>
                Have your HVAC system checked annually to prevent your heating
                system from crashing during the winter months. The freezing
                temperatures can quickly affect your pipes.
              </li>
              <li>
                Allow a small, slow drip to come from your faucets. Running the
                water even a minimal amount will help prevent frozen pipes.
              </li>
            </ul>
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  );
};
