import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

/* eslint-disable react/prop-types */
export const Layout = ({ children }) => {
  return (
    <div className="h-full flex flex-col">
      <Header />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  );
};
