/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
import Banner from "../../assets/img/projects2-banner.png";
import { Helmet } from "react-helmet-async";

import cardImg2 from "../../assets/img/card2.png";
import cardImg2_2 from "../../assets/img/card2-2.png";
import cardImg2_3 from "../../assets/img/card2-3.png";
import cardImg2_4 from "../../assets/img/card2-4.jpg";
import cardImg2_5 from "../../assets/img/card2-5.jpg";
import cardImg2_6 from "../../assets/img/card2-6.jpg";

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: cardImg2 },
  { url: cardImg2_2 },
  { url: cardImg2_3 },
  { url: cardImg2_4 },
  { url: cardImg2_5 },

  { url: cardImg2_6 },
];

export const NYCSCA19x = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>New York School at Bronx | Vital Plumbing Projects</title>
        <meta
          name="description"
          content="Check out our work at NY School in Bronx. Your plumbing and HVAC solution in NY. Call us for a free quote."
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            NYCSCA PS 19X NEW SCHOOL
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Owner:</p>
                  <p className="text-xl">
                    New York City School Construction Authority
                  </p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Architect:
                  </p>
                  <p className="text-xl">RKTB Architects P.C.</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Location:
                  </p>
                  <p className="text-xl">Bronx, New York</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Completion:
                  </p>
                  <p className="text-xl">2022</p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  PS 19X is located in the Bronx New York and is a 50,000 sft
                  New Construction. The Construction Management is performed by
                  the New York City School Construction Authority and the design
                  is by RKTB Architects. Concrete on deck structural steel
                  superstructure, supported by spread footers, brick and block
                  exterior envelope, aluminum windows and storefronts, two new
                  elevators, new HVAC systems with three new RTUs. New
                  standalone services, gas, electric, sewer and water services.
                  Fire protection systems, fire alarm, intrusion alarm will also
                  be installed and commissioned. The project is under the NYC
                  Green Schools Guide and Rating System and will achieve
                  compliance with the Local Law 86 of 2005.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
