/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
// import Banner from "../../assets/img/projects2-banner.png";
import { Helmet } from "react-helmet-async";

import cardImg6 from "../../assets/img/card6.png";
import cardImg6_2 from "../../assets/img/card6-2.jpg";
import cardImg6_3 from "../../assets/img/card6-3.jpg";

import SimpleImageSlider from "react-simple-image-slider";

const images = [{ url: cardImg6 }, { url: cardImg6_2 }, { url: cardImg6_3 }];

export const RedHookHouses = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Planning and design for Red Hook Houses | Vital Plumbing Projects
        </title>
        <meta
          name="description"
          content="Check out our planning work at Red Hook Houses. Your plumbing and HVAC solution in NY. Call us for a free quote."
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${cardImg6})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            RED HOOK HOUSES
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Type:</p>
                  <p className="text-xl">
                    Planning + Urban Design, Residential
                  </p>
                  <p className="text-base text-grey-1 font-light">Client:</p>
                  <p className="text-xl">New York City Housing Authority</p>
                  <p className="text-base text-grey-1 font-light">Location:</p>
                  <p className="text-xl">New York, NY, USA</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Status:
                  </p>
                  <p className="text-xl">Built 2019</p>
                  <p className="text-base text-grey-1 font-light mt-4">Size:</p>
                  <p className="text-xl">241,600 m2 / 2,600,000 ft2</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Units:
                  </p>
                  <p className="text-xl">2873 Residences</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Awards:
                  </p>
                  <p className="text-xl">
                    American Architecture Award (Chicago Athenaeum 2020), Merit
                    – Urban Design (AIA New York 2017), Merit Award – Urban
                  </p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  KPF and NYCHA devised a resiliency and renewal program that
                  would lessen the community’s vulnerability to natural
                  disasters and improve the sustainability and livability of the
                  development’s 28 buildings housing over 6,000 people.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
