import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { PlumbingPage } from "./pages/PlumbingPage";
import { InspectionPage } from "./pages/InspectionPage";
import { FireProtectionPage } from "./pages/FireProtectionPage";
import { HeatingCoolingServicePage } from "./pages/HeatingCoolingServicePage";
import { ServicesPage } from "./pages/ServicesPage";
import { NYCSCASchoolPage } from "./pages/NYCSCASchoolPage";
import { NYCSCA19x } from "./pages/NYCSCA19X";
import { PsTrinityPlace } from "./pages/PSTrinityPlace";
import { RescueCompany2 } from "./pages/RescueCompany2";
import { PS575 } from "./pages/PS575";
import { RedHookHouses } from "./pages/RedHookHouses";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/plumbing" element={<PlumbingPage />} />
      <Route path="/inspection" element={<InspectionPage />} />
      <Route path="/fire-protection" element={<FireProtectionPage />} />
      <Route
        path="/heating-cooling-service"
        element={<HeatingCoolingServicePage />}
      />
      <Route path="/nycsca-ps-129q-new-school" element={<NYCSCASchoolPage />} />
      <Route path="/nycsca-ps-19x-new-school" element={<NYCSCA19x />} />
      <Route path="/ps-464m-trinity-place" element={<PsTrinityPlace />} />
      <Route path="/rescue-company-2" element={<RescueCompany2 />} />
      <Route path="/ps-575-academy-of-american-studies" element={<PS575 />} />
      <Route path="/red-hook-houses" element={<RedHookHouses />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    </Routes>
  );
};
