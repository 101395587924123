/* eslint-disable react/prop-types */
import React from "react";

export const Button = ({ variant, size, children, className, ...rest }) => {
  let buttonClasses = "px-4 py-2 cursor-pointer outline-none";

  switch (variant) {
    case "primary":
      buttonClasses += ` bg-secondary text-primary hover:bg-opacity-80 transition ease-in-out duration-300 ${className}`;
      break;
    case "outline":
      buttonClasses += ` border border-white border-solid text-white hover:bg-white hover:text-black transition ease-in-out duration-300 ${className}`;
      break;
    case "moving":
      buttonClasses += ` bg-white inline-block relative overflow-hidden text-center shadow-button-blue transition duration-200 border-2 border-transparent hover:shadow-none hover:-translate-x-2 hover:translate-y-2`;
      break;
    case "movingBlue":
      buttonClasses += ` bg-primary text-white inline-block relative overflow-hidden text-center shadow-button-blue transition duration-200 border-2 border-transparent hover:shadow-none hover:-translate-x-2 hover:translate-y-2 ${className}`;
      break;
    default:
      buttonClasses += " bg-white text-gray-700";
  }

  if (size === "sm") {
    buttonClasses += " text-sm";
  }

  return (
    <button className={buttonClasses} {...rest}>
      {children}
    </button>
  );
};
