import * as React from "react";
export const ViolationsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}
  >
    <rect width={64} height={63.997} x={8} y={8.001} fill="#fff" rx={31.998} />
    <g fill="#051E47" clipPath="url(#a)">
      <path d="M30.55 26.938v-5.304l-6.491 5.992h5.803c.38 0 .688-.308.688-.688ZM56.73 56.713a.682.682 0 0 0 .247-.935l-4.479-7.768a9.616 9.616 0 0 1-1.194.688l3.213 5.557.008.02 1.263 2.188a.688.688 0 0 0 .942.25ZM53.113 38.598a6.173 6.173 0 0 0-.223-.727 6.02 6.02 0 0 0-.258-.595l-.012-.025-.004-.011c-.055-.114-.108-.207-.16-.302a6.144 6.144 0 0 0-3.753-2.875 6.187 6.187 0 1 0 3.916 8.769v-.004c.1-.201.189-.408.266-.619.015-.038.028-.077.042-.117.072-.202.134-.41.185-.618v-.01c.05-.22.09-.444.117-.668V39.28a6.377 6.377 0 0 0-.115-.661l-.001-.02Zm-2.03.206a.688.688 0 0 1-.919-.314l-.075-.145a3.46 3.46 0 0 0-2.996-1.738c-.6.001-1.188.16-1.707.46a.688.688 0 1 1-.688-1.192 4.812 4.812 0 0 1 6.572 1.763c.034.06.068.122.099.185l.03.061c.164.34.024.75-.315.917v.003Z" />
      <path d="M22.98 58.562c0 .38.308.687.688.687h28.874c.38 0 .688-.308.688-.687V54.79l-3.218-5.572c-.05.017-.102.026-.152.041a9.79 9.79 0 0 1-.367.1 8.635 8.635 0 0 1-.834.174c-.158.027-.317.05-.475.07a9.798 9.798 0 0 1-.842.054c-.069 0-.137.01-.201.01s-.146-.006-.218-.008a9.732 9.732 0 0 1-.8-.051c-.063-.008-.125-.011-.187-.02a9.99 9.99 0 0 1-.713-.117c-.06-.01-.117-.027-.176-.04a7.744 7.744 0 0 1-.54-.137 8.988 8.988 0 0 1-.725-.237c-.068-.025-.137-.05-.206-.077a9.762 9.762 0 0 1-1.316-.636l-.185-.112a9.866 9.866 0 0 1-.447-.29 10.062 10.062 0 0 1-.65-.491l-.128-.103a9.16 9.16 0 0 1-.546-.5c-.04-.04-.078-.083-.117-.123a8.168 8.168 0 0 1-.393-.428l-.157-.188a9.16 9.16 0 0 1-.329-.427c-.045-.063-.091-.124-.138-.187a10.112 10.112 0 0 1-.412-.644c-2.655-4.602-1.076-10.486 3.527-13.14.23-.131.46-.252.69-.362.07-.034.143-.06.213-.092.164-.074.329-.148.495-.21.088-.034.176-.06.263-.091.152-.053.304-.108.457-.153.096-.028.193-.05.289-.076.146-.038.293-.077.441-.109.1-.02.202-.037.303-.055.144-.025.288-.05.433-.068.104-.013.206-.022.313-.032.142-.014.284-.028.427-.035.105-.006.212-.007.319-.01.137 0 .28-.003.42 0 .108 0 .215.01.323.016a9.558 9.558 0 0 1 .74.072 7.894 7.894 0 0 1 .73.132 9.515 9.515 0 0 1 2.108.728c.119.057.235.119.352.18l.313.173.337.206c.11.07.206.13.304.2.099.07.215.157.321.238.106.08.197.145.293.224.02.016.042.03.062.048V21.438a.687.687 0 0 0-.688-.687H31.919v6.187a2.063 2.063 0 0 1-2.063 2.063H22.98v29.56Zm9.625-25.437h4.813a.688.688 0 0 1 0 1.375h-4.813a.688.688 0 0 1 0-1.375Zm-5.5 0h2.75a.688.688 0 0 1 0 1.375h-2.75a.687.687 0 1 1 0-1.375Zm0 4.125h8.938a.687.687 0 1 1 0 1.375h-8.938a.687.687 0 1 1 0-1.375Zm0 4.125h8.938a.687.687 0 1 1 0 1.375h-8.938a.687.687 0 1 1 0-1.375Zm0 4.125h10.313a.688.688 0 0 1 0 1.375H27.105a.687.687 0 1 1 0-1.375Zm0 4.125h14.437a.688.688 0 0 1 0 1.374H27.105a.687.687 0 1 1 0-1.374Z" />
      <path d="M54.244 35.914a7.326 7.326 0 0 0-.843-1.181 5.694 5.694 0 0 0-.635-.675 8.623 8.623 0 0 0-.572-.491c-.062-.05-.126-.094-.19-.138-.137-.102-.275-.2-.412-.293-.076-.05-.154-.097-.232-.144a8.051 8.051 0 0 0-1.087-.556c-.076-.032-.152-.069-.23-.096a7.654 7.654 0 0 0-.533-.183c-.05-.015-.098-.034-.148-.048a8.055 8.055 0 0 0-.695-.167c-.063-.012-.127-.02-.19-.03a8.204 8.204 0 0 0-.515-.073c-.089-.01-.177-.015-.265-.02-.15-.012-.3-.02-.45-.022h-.243a8.148 8.148 0 0 0-.923.06c-.038.005-.075.013-.113.018a8.32 8.32 0 0 0-.85.169c-.06.015-.12.027-.178.044a8.259 8.259 0 0 0-.887.3c-.063.024-.124.052-.187.08a8.434 8.434 0 0 0-.894.437 8.242 8.242 0 0 0-3.018 11.26 8.29 8.29 0 0 0 10.114 3.575 8.514 8.514 0 0 0 2.21-1.272 7.31 7.31 0 0 0 .475-.42c.227-.211.441-.436.641-.674a8.27 8.27 0 0 0 .85-9.458v-.002Zm.219 2.433c.06.256.106.515.137.776v.026a7.6 7.6 0 0 1 .006 1.727v.01a.696.696 0 0 1-.006.085 7.494 7.494 0 0 1-.144.785l-.02.077c-.058.236-.127.47-.206.7-.02.055-.037.11-.058.164-.093.251-.199.496-.317.736l-.007.018a7.579 7.579 0 0 1-6.738 4.159 7.578 7.578 0 0 1-1.964-.26 7.562 7.562 0 1 1 8.502-11.09c.068.128.137.25.2.377v.01c0 .007.005.014.009.02.116.234.22.473.31.717.023.061.043.122.065.184.073.211.137.425.19.642.017.046.03.091.04.14v-.003Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M20.75 20.751h38.499v38.498H20.75z" />
      </clipPath>
    </defs>
  </svg>
);
