/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { ContactForm } from "../../components/ContactForm";
import ServiceBack from "../../assets/img/service-background.png";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { GasElectric } from "../../icons/services/gas-electric";
import { GreaseTrap } from "../../icons/services/grease-trap";
import { FilterInstallation } from "../../icons/services/filter-installation";
import { BoilerInspection } from "../../icons/services/boiler-inspectation";
import { BackflowInstallation } from "../../icons/services/backflow-installation";
import { DrainMaintenance } from "../../icons/services/drain-maintenance";
import { FDNYHydrostatics } from "../../icons/services/fdny-hydrostatic";
import { Estimating } from "../../icons/services/estimating";
import { Design3D } from "../../icons/services/3d-design";
import { EmergencyService } from "../../icons/services/emergency-service";
import { Helmet } from "react-helmet-async";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const PlumbingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <>
      <Helmet>
        <title>Plumbing services in NYC </title>
        <meta
          name="description"
          content="Commercial and industrial pluming services in Brooklyn, Queens, Bronx, Manhatten, Staten Island. Call us for a quick plumbing solutions."
        />
      </Helmet>
      <img src={ServiceBack} alt="Vital Service" className="w-full" />
      <div className="bg-[#F9FAFB]">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <h1 className="text-primary font-bold text-4xl text-center mb-12">
            VITAL PLUMBING SERVICES
          </h1>
          <div className="flex flex-col px-5 w-full">
            <Accordion open={open === 0} icon={<Icon id={0} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(0)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <GasElectric /> Gas & Electric Hot Water Heaters
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                It’s essential to have the contact information of a reliable
                Brooklyn plumber readily available whenever you require water
                heater services. Since 2001 Vital has been a trusted and
                reliable source for assistance in the local area. We have
                extensive experience working on water heaters in countless
                buildings & businesses throughout NYC. Whenever you need
                assistance with your water heater, feel free to reach out to us.
                For prompt and professional service, please contact us at (718)
                717-1089.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <GreaseTrap /> Grease Trap Maintenance
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                Proper maintenance of grease traps is essential to ensure the
                efficient operation of commercial kitchens and prevent potential
                issues. Grease traps are plumbing devices designed to capture
                and separate fats, oils, and grease (FOG) from wastewater,
                preventing their entry into the sewer system. Regular
                maintenance of grease traps helps to avoid clogs, foul odors,
                and environmental contamination. Through routine maintenance,
                including grease trap cleaning, inspection, and proper disposal
                of collected FOG, we help businesses maintain a clean and
                efficient kitchen environment. Our professional team is equipped
                with the necessary tools and expertise to handle grease trap
                maintenance effectively.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <FilterInstallation /> Filter Installation and Maintenance
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                Filters play a crucial role in maintaining the quality and
                efficiency of various systems and equipment. From HVAC systems
                to water treatment units, proper filter installation and
                maintenance are essential for optimal performance and longevity.
                During the installation process, we carefully assess your
                system&#39;s requirements and recommend appropriate filters that
                meet your needs. Our technicians are trained to handle various
                types of filters, including air filters, water filters, oil
                filters, and more. Regular filter maintenance is vital to
                prevent clogs, improve system efficiency, and maintain healthy
                indoor air quality. Our experts can schedule routine filter
                replacements and cleanings to ensure your systems continue to
                function optimally. We also provide thorough inspections to
                identify any potential issues and offer proactive solutions.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <BoilerInspection /> Annual Boiler Inspection NYC DOB
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                In New York City, it is mandatory to conduct annual boiler
                inspections to ensure the safety and proper functioning of
                boiler systems. The New York City Department of Buildings (DOB)
                oversees and enforces these regulations to protect the
                well-being of residents and maintain compliance with safety
                standards. During the inspection, our technicians thoroughly
                assess various components of the boiler system, including
                controls, safety devices, venting, piping, and overall system
                operation. We check for any signs of wear and tear, potential
                leaks, blockages, or other issues that may compromise the
                boiler&#39;s safety or efficiency. After the inspection, we
                provide a detailed report of our findings, along with any
                recommended repairs or maintenance tasks. We strive to ensure
                that your boiler meets all safety regulations and operates at
                its optimal performance level.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(4)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <BackflowInstallation /> Backflow Preventer Installation &
                  Annual Testing
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                Backflow preventers are crucial devices that help protect the
                potable water supply from potential contamination by preventing
                the reverse flow of water. Installing backflow preventers and
                conducting annual testing are essential to ensure the safety and
                integrity of the water supply system. During the installation
                process, we assess your specific requirements and recommend the
                appropriate type and size of backflow preventer for your
                property. We ensure that the installation adheres to local
                regulations and industry standards. Annual testing is necessary
                to verify the functionality and effectiveness of the backflow
                preventer. Our licensed technicians perform rigorous testing to
                check for any potential issues, such as worn-out parts, valve
                malfunctions, or pressure loss. If any problems are detected, we
                provide the necessary repairs or replacements to ensure optimal
                performance. At Vital we understand the importance of compliance
                with local regulations, and we assist you in submitting the
                required documentation to the appropriate authorities after
                successful testing.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(5)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <DrainMaintenance /> Drain Maintenance
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                Your plumbing system, although concealed within walls and
                beneath your home, is a meticulously designed network of pipes
                that ensures immediate access to water at all fixtures. While
                the concept of indoor plumbing was once unfamiliar, it is now
                unimaginable to live without reliable plumbing. At Vital, our
                team of skilled plumbers recognizes the significance of your
                drainage system and is well-versed in the issues that can
                occasionally arise. When faced with a clogged drain it is
                essential to seek professional drain cleaning services. You can
                trust us to provide this vital service as part of our
                comprehensive plumbing offerings.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(6)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <FDNYHydrostatics /> FDNY Hydrostatic 5 Year Testing, Annual
                  DEP Testing
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                In New York City, it is mandatory to conduct FDNY hydrostatic
                5-year testing and annual DEP testing for certain systems to
                ensure compliance with safety regulations and maintain the
                integrity of the infrastructure. FDNY hydrostatic 5- year
                testing involves testing the integrity and pressure resistance
                of fire sprinkler systems and standpipes. Our skilled
                professionals conduct thorough inspections and perform
                hydrostatic pressure tests to ensure that these systems can
                effectively respond to fire emergencies. After conducting the
                necessary tests, we provide detailed reports and assist you in
                submitting the required documentation to the appropriate
                authorities.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(7)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <Estimating /> Estimating & Project Management
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                Accurate estimating and effective project management are
                essential elements of any successful construction or renovation
                project. At Vital Plumbing, we offer comprehensive estimating
                and project management services to ensure the smooth execution
                of your plumbing projects. Our experienced team is skilled in
                providing accurate and detailed cost estimates for plumbing
                installations, repairs, or system upgrades. We consider factors
                such as materials, labor, equipment, permits, and any other
                necessary expenses to provide you with a transparent and
                reliable estimate. Once the project is underway, our dedicated
                project managers oversee every aspect of the plumbing project.
                They coordinate with our team of skilled technicians, manage
                schedules, monitor progress, and ensure that all work is carried
                out according to the highest industry standards.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(8)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <Design3D /> Design and Coordination Using 3-D Technology
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                At Vital Plumbing, we leverage cutting-edge 3-D technology to
                enhance the design and coordination process for plumbing
                projects. Our team is proficient in utilizing advanced software
                and tools to create detailed 3-D models, enabling accurate
                visualization and precise planning. With 3-D technology, we can
                create virtual representations of plumbing systems, fixtures,
                and layouts, providing a realistic view of how the project will
                look once completed. This allows for better communication and
                collaboration among stakeholders, including architects,
                contractors, and clients, ensuring a shared understanding of the
                design, and minimizing potential errors or misunderstandings.
                Through 3-D modeling, we can assess the feasibility of plumbing
                installations, identify potential clashes or conflicts with
                other building components, and make necessary adjustments before
                starting the construction phase. This proactive approach helps
                streamline the process, minimize rework, and enhance overall
                project efficiency.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 9} icon={<Icon id={9} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(9)}
                className="py-4 text-xl border-b-2 border-grey-2"
              >
                <h2 className="flex items-center gap-4 text-blue-2">
                  <EmergencyService /> *24/7 Emergency Service
                </h2>
              </AccordionHeader>
              <AccordionBody className="text-base">
                When faced with a plumbing emergency in Brooklyn, it is crucial
                to address the problems promptly and effectively to prevent
                extensive damage. Fortunately, as an emergency plumber in
                Brooklyn, we possess the expertise to handle a wide range of
                plumbing services, including urgent situations like burst frozen
                pipes, significant plumbing leaks, overflowing toilets, and
                various faucet issues. If you find yourself dealing with any of
                these issues, don&#39;t hesitate to reach out to us for your
                emergency plumbing needs in Brooklyn. We have earned the trust
                of the community for over a century, and we are committed to
                providing reliable and timely assistance during plumbing
                emergencies.
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
