/* eslint-disable no-unused-vars */
export const Sprinkler = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M36.4992 9.77991V4.99561H3.5V9.77991H19.0328V11.5334H16.9818V13.467H23.0174V11.5334H20.9664V9.77991H36.4992Z"
      fill="#0253B3"
    />
    <path
      d="M20.9667 26.9814H19.0332V29.6554H20.9667V26.9814Z"
      fill="#0253B3"
    />
    <path
      d="M20.9667 21.6328H19.0332V24.3068H20.9667V21.6328Z"
      fill="#0253B3"
    />
    <path d="M20.9667 32.3301H19.0332V35.004H20.9667V32.3301Z" fill="#0253B3" />
    <path
      d="M20.9667 16.2856H19.0332V18.9596H20.9667V16.2856Z"
      fill="#0253B3"
    />
    <path
      d="M26.3861 25.5198L24.5996 26.2598L25.623 28.7303L27.4094 27.9903L26.3861 25.5198Z"
      fill="#0253B3"
    />
    <path
      d="M24.3372 20.5793L22.5508 21.3193L23.5741 23.7899L25.3606 23.0499L24.3372 20.5793Z"
      fill="#0253B3"
    />
    <path
      d="M28.431 30.4602L26.6445 31.2002L27.6679 33.6707L29.4543 32.9307L28.431 30.4602Z"
      fill="#0253B3"
    />
    <path
      d="M27.0449 18.3144L25.6777 19.6816L27.5685 21.5724L28.9357 20.2052L27.0449 18.3144Z"
      fill="#0253B3"
    />
    <path
      d="M30.8281 22.0962L29.4609 23.4634L31.3517 25.3541L32.7189 23.9869L30.8281 22.0962Z"
      fill="#0253B3"
    />
    <path
      d="M34.6094 25.8774L33.2422 27.2446L35.133 29.1354L36.5002 27.7682L34.6094 25.8774Z"
      fill="#0253B3"
    />
    <path
      d="M23.2656 14.5332L21.8984 15.9004L23.7892 17.7912L25.1564 16.4239L23.2656 14.5332Z"
      fill="#0253B3"
    />
    <path
      d="M13.6191 25.5182L12.5957 27.9888L14.3822 28.7288L15.4055 26.2582L13.6191 25.5182Z"
      fill="#0253B3"
    />
    <path
      d="M11.5741 30.4596L10.5508 32.9302L12.3372 33.6702L13.3606 31.1996L11.5741 30.4596Z"
      fill="#0253B3"
    />
    <path
      d="M15.6659 20.5778L14.6426 23.0483L16.429 23.7883L17.4524 21.3178L15.6659 20.5778Z"
      fill="#0253B3"
    />
    <path
      d="M5.39081 25.8773L3.5 27.7681L4.86721 29.1353L6.75802 27.2445L5.39081 25.8773Z"
      fill="#0253B3"
    />
    <path
      d="M9.17396 22.0956L7.2832 23.9863L8.65041 25.3535L10.5412 23.4628L9.17396 22.0956Z"
      fill="#0253B3"
    />
    <path
      d="M12.9552 18.3138L11.0645 20.2046L12.4317 21.5718L14.3224 19.681L12.9552 18.3138Z"
      fill="#0253B3"
    />
    <path
      d="M16.7345 14.5326L14.8438 16.4233L16.211 17.7906L18.1017 15.8998L16.7345 14.5326Z"
      fill="#0253B3"
    />
  </svg>
);
