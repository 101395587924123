import * as React from "react";
export const AirConditionWhiteIcon = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" y="8" width="64.0017" height="64" rx="32" fill="#F5B000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.0234 47.5308H58.6484V45.0386V43.062V41.1714C58.6484 40.8276 58.3906 40.5698 58.0469 40.5698H23.6719C23.3281 40.5698 23.0703 40.8276 23.0703 41.1714V43.062V45.0386V47.5308H19.9766C18.8594 47.5308 18 46.6714 18 45.5542V26.9917C18 25.8745 18.8594 25.0151 19.9766 25.0151H22.2969V27.2495C22.2969 28.4526 23.5 29.3979 24.875 29.3979H55.125C56.5 29.3979 57.7031 28.4526 57.7031 27.2495V25.0151H60.0234C61.1406 25.0151 62 25.8745 62 26.9917V45.5542C62 46.6714 61.1406 47.5308 60.0234 47.5308ZM55.5547 37.9058C53.9219 37.9058 52.6328 36.6167 52.6328 34.9839C52.6328 33.3511 53.9219 32.062 55.5547 32.062C57.1875 32.062 58.4766 33.3511 58.4766 34.9839C58.4766 36.6167 57.1875 37.9058 55.5547 37.9058ZM55.5547 33.2651C54.6094 33.2651 53.8359 34.0386 53.8359 34.9839C53.8359 35.9292 54.6094 36.7026 55.5547 36.7026C56.5 36.7026 57.2734 35.9292 57.2734 34.9839C57.2734 34.0386 56.5 33.2651 55.5547 33.2651ZM47.3906 37.9058C45.7578 37.9058 44.4688 36.6167 44.4688 34.9839C44.4688 33.3511 45.7578 32.062 47.3906 32.062C48.9375 32.062 50.2266 33.3511 50.2266 34.9839C50.2266 36.6167 48.9375 37.9058 47.3906 37.9058ZM47.3906 33.2651C46.4453 33.2651 45.6719 34.0386 45.6719 34.9839C45.6719 35.9292 46.4453 36.7026 47.3906 36.7026C48.25 36.7026 49.0234 35.9292 49.0234 34.9839C49.0234 34.0386 48.25 33.2651 47.3906 33.2651ZM23.5 25.0151H56.5V27.2495C56.5 27.7651 55.8125 28.1948 55.125 28.1948H24.875C24.1875 28.1948 23.5 27.7651 23.5 27.2495V25.0151ZM57.4453 47.5308H24.2734V45.6401H57.4453V47.5308ZM24.2734 44.437V43.6636H57.4453V44.437H24.2734ZM24.2734 42.4604V41.7729H57.4453V42.4604H24.2734Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7656 56.9839C26.6797 56.9839 26.5078 56.9839 26.4219 56.8979C26.25 56.812 24.7891 55.7808 24.7891 54.4058C24.7891 53.7183 25.2187 53.0308 26.0781 52.4292C26.5937 52.0854 26.8516 51.7417 26.9375 51.312C27.0234 50.5386 26.25 49.6792 25.9922 49.4214C25.7344 49.1636 25.7344 48.8198 25.9922 48.562C26.25 48.3042 26.5937 48.3042 26.8516 48.562C26.8516 48.6479 28.3125 49.937 28.1406 51.4839C28.0547 52.2573 27.5391 52.9448 26.7656 53.4604C26.25 53.8042 25.9922 54.1479 25.9922 54.4058C25.9922 55.0073 26.7656 55.6948 27.1094 55.8667C27.3672 56.0386 27.4531 56.4683 27.2812 56.7261C27.1953 56.8979 26.9375 56.9839 26.7656 56.9839Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5547 56.9839C33.4687 56.9839 33.2969 56.9839 33.2109 56.8979C33.0391 56.812 31.5781 55.7808 31.5781 54.4058C31.5781 53.7183 32.0078 53.0308 32.8672 52.4292C33.3828 52.0854 33.6406 51.7417 33.7266 51.312C33.8125 50.5386 33.0391 49.6792 32.7812 49.4214C32.5234 49.1636 32.5234 48.8198 32.7812 48.562C33.0391 48.3042 33.3828 48.3042 33.6406 48.562C33.6406 48.6479 35.1016 49.937 34.9297 51.4839C34.8437 52.2573 34.3281 52.9448 33.5547 53.4604C33.0391 53.8042 32.7812 54.1479 32.7812 54.4058C32.7812 55.0073 33.5547 55.6948 33.8984 55.8667C34.1562 56.0386 34.2422 56.4683 34.0703 56.7261C33.9844 56.8979 33.7266 56.9839 33.5547 56.9839Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2578 56.9839C40.1719 56.9839 40 56.9839 39.9141 56.8979C39.7422 56.812 38.3672 55.7808 38.3672 54.4917C38.3672 53.7183 38.7969 53.0308 39.6562 52.4292C40.1719 52.0854 40.4297 51.7417 40.4297 51.3979C40.6016 50.4526 39.5703 49.4214 39.5703 49.4214C39.3125 49.1636 39.3125 48.8198 39.5703 48.562C39.8281 48.3042 40.1719 48.3042 40.4297 48.562C40.5156 48.6479 41.8047 50.0229 41.6328 51.4839C41.5469 52.2573 41.1172 52.9448 40.3438 53.4604C39.8281 53.8042 39.5703 54.1479 39.5703 54.4917C39.5703 55.0933 40.3438 55.7808 40.6016 55.8667C40.8594 56.0386 40.9453 56.4683 40.7734 56.7261C40.6016 56.8979 40.4297 56.9839 40.2578 56.9839Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.0459 56.9839C46.96 56.9839 46.7881 56.9839 46.7022 56.8979C46.5303 56.812 45.1553 55.7808 45.1553 54.4917C45.0694 53.7183 45.4991 53.0308 46.3584 52.4292C46.8741 52.0854 47.1319 51.7417 47.2178 51.3979C47.3038 50.6245 46.5303 49.6792 46.2725 49.4214C46.0147 49.1636 46.0147 48.8198 46.2725 48.562C46.5303 48.3042 46.8741 48.3042 47.1319 48.562C47.2178 48.6479 48.5928 49.937 48.4209 51.4839C48.335 52.2573 47.8194 52.9448 47.0459 53.4604C46.5303 53.8042 46.3584 54.1479 46.3584 54.4058C46.3584 55.0933 47.1319 55.6948 47.3897 55.8667C47.6475 56.0386 47.7334 56.4683 47.5616 56.7261C47.3897 56.8979 47.2178 56.9839 47.0459 56.9839Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.8359 56.9839C53.75 56.9839 53.5781 56.9839 53.4922 56.8979C53.3203 56.812 51.8594 55.7808 51.8594 54.4058C51.8594 53.7183 52.2891 53.0308 53.1484 52.4292C53.6641 52.0854 53.9219 51.7417 54.0078 51.312C54.0937 50.5386 53.3203 49.6792 53.0625 49.4214C52.8047 49.1636 52.8047 48.8198 53.0625 48.562C53.3203 48.3042 53.6641 48.3042 53.9219 48.562C53.9219 48.6479 55.3828 49.937 55.2109 51.4839C55.125 52.2573 54.6094 52.9448 53.8359 53.4604C53.3203 53.8042 53.0625 54.1479 53.0625 54.4058C53.0625 55.0073 53.75 55.6948 54.1797 55.8667C54.4375 56.0386 54.5234 56.4683 54.3516 56.7261C54.2656 56.8979 54.0078 56.9839 53.8359 56.9839Z"
      fill="white"
    />
  </svg>
);
