/* eslint-disable no-unused-vars */
export const DrainMaintenance = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M27.4298 19.4287C26.4453 19.4268 25.4812 19.7085 24.6526 20.2401L28.3955 21.2459C28.4782 21.2695 28.5548 21.3109 28.6199 21.3672C28.6851 21.4234 28.7372 21.4932 28.7726 21.5716L30.2412 24.9201C30.2919 25.0389 30.3008 25.1714 30.2666 25.2959C30.2324 25.4204 30.1569 25.5297 30.0526 25.6059L27.1041 27.783C27.0347 27.8327 26.9552 27.8666 26.8713 27.8824C26.7874 27.8982 26.7011 27.8955 26.6184 27.8744L22.8184 26.8516C23.2718 27.7576 23.9811 28.5106 24.8584 29.0173C24.9456 29.0676 25.0179 29.1401 25.0681 29.2274C25.1183 29.3147 25.1445 29.4137 25.1441 29.5144V36.0002H29.7155V29.5144C29.7151 29.4137 29.7413 29.3147 29.7915 29.2274C29.8417 29.1401 29.914 29.0676 30.0012 29.0173C30.9816 28.452 31.7479 27.5789 32.1812 26.5334C32.6144 25.488 32.6904 24.3287 32.3973 23.2356C32.1041 22.1426 31.4583 21.1769 30.5601 20.4885C29.6618 19.8001 28.5615 19.4276 27.4298 19.4287Z"
      fill="#0253B3"
    />
    <path
      d="M21.3493 26.1656C21.3333 26.1178 21.3256 26.0675 21.3264 26.0171V26.0056C21.3265 25.9113 21.3499 25.8185 21.3946 25.7354C21.4393 25.6524 21.5038 25.5817 21.5825 25.5296C21.6611 25.4775 21.7514 25.4458 21.8453 25.4371C21.9393 25.4284 22.0339 25.4431 22.1207 25.4799L25.1379 26.2914C25.1379 26.2056 25.1436 26.1199 25.1436 26.0399V22.2856H19.4293V26.2856C19.4293 26.8554 19.3077 27.4185 19.0725 27.9374C18.8373 28.4563 18.4939 28.919 18.0655 29.2945C17.637 29.67 17.1332 29.9496 16.5879 30.1146C16.0426 30.2796 15.4683 30.3262 14.9036 30.2514C13.9277 30.1069 13.0375 29.613 12.3985 28.8615C11.7595 28.11 11.415 27.152 11.4293 26.1656V22.2914H5.715V26.2856C5.70279 28.4632 6.42639 30.581 7.7685 32.2958C9.11061 34.0105 10.9925 35.2218 13.1092 35.7331C15.2259 36.2444 17.4532 36.0258 19.4301 35.1128C21.407 34.1997 23.0176 32.6458 24.0007 30.7028V29.8342C22.6914 28.9837 21.746 27.6757 21.3493 26.1656Z"
      fill="#0253B3"
    />
    <path d="M5.14258 8H11.9997V9.71429H5.14258V8Z" fill="#0253B3" />
    <path
      d="M5.71484 10.8569H11.4291V21.1484H5.71484V10.8569Z"
      fill="#0253B3"
    />
    <path d="M4 4H13.1429V6.85714H4V4Z" fill="#0253B3" />
    <path
      d="M33.1426 9.14307H35.9997V18.2859H33.1426V9.14307Z"
      fill="#0253B3"
    />
    <path
      d="M30.2852 10.2856H31.9994V17.1428H30.2852V10.2856Z"
      fill="#0253B3"
    />
    <path
      d="M23.2677 21.0569C23.1708 21.0302 23.0824 20.9788 23.0113 20.9076C22.9402 20.8365 22.8888 20.7482 22.862 20.6512C22.8364 20.5534 22.8372 20.4506 22.8643 20.3532C22.8914 20.2559 22.9439 20.1674 23.0163 20.0969C23.6227 19.4953 24.3462 19.0246 25.142 18.7141V18.2855C25.1434 17.8313 25.3244 17.396 25.6456 17.0748C25.9668 16.7536 26.4021 16.5726 26.8563 16.5712H29.142V10.8569H23.4277C22.3672 10.8581 21.3505 11.28 20.6006 12.0298C19.8508 12.7797 19.4289 13.7964 19.4277 14.8569V21.1426H23.5992L23.2677 21.0569Z"
      fill="#0253B3"
    />
  </svg>
);
