/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { ContactForm } from "../../components/ContactForm";
import InspectionBack from "../../assets/img/inspection-background.png";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { FDNYHydrostatics } from "../../icons/services/fdny-hydrostatic";
import { FireStandpipeSystems } from "../../icons/services/fire-standpipe-systems";
import { CureFDNY } from "../../icons/services/cure-FDNY";
import { DOBRequirement } from "../../icons/services/DOB-requirement";
import { DOBAndDEP } from "../../icons/services/DOB-DEP";
import { NFPA } from "../../icons/services/NFPA";
import { LocalLawCompliance } from "../../icons/services/local-law-compliance";
import { Helmet } from "react-helmet-async";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const accordionData = [
  {
    id: 0,
    title:
      "FDNY Requirement: 5-Year Hydrostatic Testing on Sprinkler/ Standpipe Systems",
    text: "In New York City, it is mandatory to conduct FDNY hydrostatic 5-year testing and annual DEP testing for certain systems to ensure compliance with safety regulations and maintain the integrity of the infrastructure. FDNY hydrostatic 5-year testing involves testing the integrity and pressure resistance of fire sprinkler systems and standpipes. Our skilled professionals conduct thorough inspections and perform hydrostatic pressure tests to ensure that these systems can effectively respond to fire emergencies. After conducting the necessary tests, we provide detailed reports and assist you in submitting the required documentation to the appropriate authorities.",
    icon: <FDNYHydrostatics />,
  },
  {
    id: 1,
    title: "Fire Standpipe Systems",
    text: "Fire standpipe systems are important components of fire protection systems in buildings. They consist of pipes, valves, hose connections, and outlets that are strategically located throughout a building to provide a reliable water supply for firefighting purposes. Violations related to fire standpipe systems typically involve non-compliance with fire codes and regulations. Here are some common fire standpipe systems violations.",
    icon: <FireStandpipeSystems />,
  },
  {
    id: 2,
    title: "Cure FDNY or NYC DOB Violations",
    text: "Curing violations issued by the New York City Fire Department (FDNY) or the New York City Department of Buildings (DOB) involves taking the necessary steps to address the violations and bring the property into compliance with the applicable regulations.",
    icon: <CureFDNY />,
  },
  {
    id: 3,
    title: "DOB Requirement: NYC LL152 Gas Inspection",
    text: "The NYC Local Law 152 (LL152) gas inspection is a requirement implemented by the New York City Department of Buildings (DOB) to ensure the safety and integrity of gas piping systems in buildings. The law mandates periodic inspections of gas piping systems in buildings that are classified as &quot;Gas Utilization&quot; (GU) and &quot;Gas Transmission&quot; (GT) occupancies.",
    icon: <DOBRequirement />,
  },
  {
    id: 4,
    title: "DOB & DEP Requirement: Annual Backflow Testing",
    text: "The New York City Department of Buildings (DOB) and the Department of Environmental Protection (DEP) have a joint requirement for annual backflow testing in certain buildings. Backflow testing is necessary to ensure the integrity of the water supply and prevent contamination of the public water system.",
    icon: <DOBAndDEP />,
  },
  {
    id: 5,
    title: "FDNY Testing: Annual, 3-Year and 5-Year",
    text: "The FDNY (Fire Department of the City of New York) requires various types of testing on fire protection systems and equipment in buildings. These tests are conducted to ensure the proper functioning and compliance of fire safety systems. The testing requirements include annual, 3-year, and 5-year testing.",
    icon: <CureFDNY />,
  },
  {
    id: 6,
    title: "NFPA 25 Testing and Compliance",
    text: "NFPA 25 is a comprehensive standard published by the National Fire Protection Association (NFPA) that provides guidelines for the inspection, testing, and maintenance of water-based fire protection systems. Compliance with NFPA 25 is crucial for ensuring the proper functioning and reliability of these systems.",
    icon: <NFPA />,
  },
  {
    id: 7,
    title: "Local Law Compliance- LL26 of 2004",
    text: "LL26 of 2004, also known as Local Law 26, is a New York City law that mandates specific fire safety requirements for existing buildings. It was implemented to enhance fire protection measures and improve occupant safety.",
    icon: <LocalLawCompliance className="ml-2" />,
  },
];

export const InspectionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      <Helmet>
        <title>Violation and inspection services in NYC</title>
        <meta
          name="description"
          content="Violation and inspection services for commercial and industrial facilities in Brooklyn, Queens, Bronx, Manhatten, Staten Island. Call us for a quick plumbing solutions."
        />
      </Helmet>
      <img src={InspectionBack} alt="Vital Service" className="w-full" />
      <div className="bg-[#F9FAFB]">
        <div className="mx-auto max-w-7xl px-5 py-28">
          <h1 className="text-primary font-bold text-4xl text-center mb-12">
            VITAL VIOLATIONS & INSPECTIONS
          </h1>
          <div className="flex flex-col  px-5 w-full ">
            {accordionData.map((item) => (
              <>
                <Accordion
                  key={item.id}
                  open={open === item.id}
                  icon={<Icon id={item.id} open={open} />}
                >
                  <AccordionHeader
                    onClick={() => handleOpen(item.id)}
                    className="py-4 text-xl border-b-2 border-grey-2"
                  >
                    <h2 className="flex items-center gap-4 text-blue-2">
                      {item.icon} {item.title}
                    </h2>
                  </AccordionHeader>
                  <AccordionBody className="text-base">
                    {item.text}
                  </AccordionBody>
                </Accordion>
              </>
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
