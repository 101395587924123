export const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#5DC5F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.667 4.167H3.333A.83.83 0 0 0 2.5 5v10c0 .46.373.833.833.833h13.334c.46 0 .833-.373.833-.833V5a.83.83 0 0 0-.833-.833Z"
    />
    <path
      stroke="#5DC5F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m2.742 4.41 6.078 6.078c.65.65 1.706.65 2.357 0l6.077-6.077"
    />
  </svg>
);
