import client1 from "../../assets/img/clients1.png";
import client2 from "../../assets/img/clients2.png";
import client3 from "../../assets/img/clients3.png";
import client4 from "../../assets/img/clients4.png";
import client5 from "../../assets/img/clients5.png";
import client6 from "../../assets/img/clients6.png";

export const OurClients = () => {
  return (
    <div className="bg-grey-2">
      <div className="mx-auto max-w-7xl px-5 py-20 flex justify-between flex-col ">
        <div className="w-full text-center md:text-left">
          <h2 className="text-primary font-bold text-4xl">OUR CLIENTS</h2>
        </div>

        <div className="flex mt-12 flex-col md:flex-row justify-between">
          <img src={client6} alt="" />
          <img src={client1} alt="Navillus" />
          <img src={client2} alt="Housing Authority" />
          <img src={client3} alt="" />
          <img src={client4} alt="New York State" />
          <img src={client5} alt="sca" />
        </div>
      </div>
    </div>
  );
};
