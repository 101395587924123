import { useEffect, useState } from "react";
import { Logo } from "../Logo";
import { PhoneIcon } from "../../icons/phoneIcon";
import { EmailIcon } from "../../icons/emailIcon";
import { Button } from "../Button";
import { NavLink } from "react-router-dom";

export const navigation = [
  {
    name: "OUR SERVICES",
    href: "/services",
    header: true,
    footer: true,
  },
  {
    name: "OUR PROJECTS",
    href: "/#projects",
    header: true,
    footer: true,
  },
  {
    name: "ABOUT",
    href: "/#about",
    header: true,
    footer: true,
  },
  {
    name: "FAQ",
    href: "/#faq",
    header: true,
    footer: true,
  },
  {
    name: "PRIVACY POLICY",
    href: "/privacy-policy",
    header: false,
    footer: true,
  },
];

export const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  // const location = useLocation();

  const handleResizeWindow = () => {
    if (window.innerWidth < 700) {
      setNavbarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);

    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  return (
    <header className="absolute top-0 w-full z-40">
      <div className="bg-black bg-opacity-20 top-0 w-full z-40">
        <div className="mx-auto max-w-7xl px-5 sm:px-6 lg:px-8">
          <div className="flex h-18 justify-between">
            <div className="flex md:justify-end w-full items-center">
              {/* <div className="flex flex-shrink-0 items-center"></div> */}
              <div className=" sm:-my-px sm:ml-6 md:space-x-14 flex w-full justify-between sm:justify-normal md:w-auto sm:space-x-12 text-white">
                <div className="flex items-center">
                  <PhoneIcon />
                  <span className="ml-1 text-sm md:text-base">
                    <a href="tel:(718) 676-4326">(718) 676-4326</a>
                  </span>
                </div>
                <div className="hidden sm:flex items-center">
                  <EmailIcon />
                  <span className="ml-1">
                    <a href="mailto: office@vitalplumbingnyc.com">
                      office@vitalplumbingnyc.com
                    </a>
                  </span>
                </div>
                <div>
                  <a href="#contactForm">
                    <Button variant="primary" className="text-sm md:text-base">
                      BOOK APPOINTMENT
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-opacity-80 top-0 w-full z-40">
        <div className="mx-auto max-w-7xl px-5 sm:px-6 lg:px-8">
          <div className="flex h-18 justify-between">
            <div className="flex justify-between w-full">
              <div className="flex flex-shrink-0 items-center mt-4">
                <NavLink to="/">
                  <Logo />
                </NavLink>
              </div>
              <div className="hidden sm:-my-px sm:ml-6 md:space-x-14 sm:flex sm:space-x-12">
                {navigation
                  .filter((item) => item.header)
                  .map((item) => (
                    <a
                      href={item.href}
                      key={item.name}
                      target={item.target}
                      className="inline-flex items-center text-white font-bold cursor-pointer"
                      rel="noreferrer"
                    >
                      <span className="hover:border-b  transition-all duration-100">
                        {item.name}
                      </span>
                    </a>
                  ))}
              </div>
            </div>

            <div className="flex items-center sm:hidden z-50">
              <button
                className="lg:hidden flex top-0 right-0 z-20 relative w-10 h-10 text-white focus:outline-none"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                  <span
                    className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                      navbarOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
                    }`}
                  ></span>
                  <span
                    className={`absolute h-0.5 bg-white transform transition-all duration-200 ease-in-out ${
                      navbarOpen
                        ? "w-0 opacity-50"
                        : "w-5 delay-200 opacity-100"
                    }`}
                  ></span>
                  <span
                    className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                      navbarOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
                    }`}
                  ></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <nav
        className={`absolute top-0 left-0 w-full px-5 h-screen pt-24 bg-primary text-white bg-opacity-100 transform delay-100 transition-all duration-300 z-30 ${
          navbarOpen
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-full"
        }`}
      >
        <NavLink to="/" className="-mt-20 absolute">
          <Logo />
        </NavLink>
        <div className="space-y-1  pb-4 py-10">
          {navigation
            .filter((item) => item.header)
            .map((item) => (
              <a
                key={item.name}
                href={item.href}
                target={item.target}
                className="block py-2 pr-lg text-white cursor-pointer"
                rel="noreferrer"
                onClick={() => setNavbarOpen(false)}
              >
                {item.name}
              </a>
            ))}
          {/* <div className="text-secondary-100 pt-lg">
            {translate("common.copyright")}
          </div> */}
        </div>
      </nav>
    </header>
  );
};
