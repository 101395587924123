/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
import Banner from "../../assets/img/rescue-company-2.png";
import { Helmet } from "react-helmet-async";

import cardImg4 from "../../assets/img/card4.png";
import cardImg4_2 from "../../assets/img/card4-2.png";
import cardImg4_3 from "../../assets/img/card4-3.png";
import cardImg4_4 from "../../assets/img/card4-4.png";

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: cardImg4 },
  { url: cardImg4_2 },
  { url: cardImg4_3 },
  { url: cardImg4_4 },
];

export const RescueCompany2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Rescue Company 2 in NYC | Vital Plumbing Projects</title>
        <meta
          name="description"
          content="Check out our work at Rescue Company 2 in NYC. Your plumbing and HVAC solution in NY. Call us for a free quote."
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${Banner})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            RESCUE COMPANY 2
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Type:</p>
                  <p className="text-xl">Civic</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Client:
                  </p>
                  <p className="text-xl">
                    New York City Department of Design and Construction and Fire
                    Department of the City of New York
                  </p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Location:
                  </p>
                  <p className="text-xl">Brooklyn, NY</p>
                  <p className="text-base text-grey-1 font-light mt-4">
                    Status:
                  </p>
                  <p className="text-xl">Built 2019</p>
                  <p className="text-base text-grey-1 font-light mt-4">Size:</p>
                  <p className="text-xl">21,400 SF</p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  Located in Brooklyn’s Brownsville neighborhood, the new Rescue
                  Company 2 facility is designed as a tool for training,
                  enabling FDNY’s elite force of specialized rescue workers to
                  stage and simulate a wide range of emergency conditions in,
                  on, and around the building.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
