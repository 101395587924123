import { NavLink } from "react-router-dom";
import { navigation } from "../Header";
import { Logo } from "../Logo";

export const Footer = () => {
  return (
    <footer className="w-full bg-primary text-white">
      <div className="mx-auto max-w-7xl px-5 py-12 flex flex-col md:flex-row  justify-between items-center relative">
        {/* <div className="w-[140px] h-[92px]">
          <Logo />
        </div> */}
        <NavLink to="/" className="absolute md:relative">
          <Logo />
        </NavLink>
        <div className="py-8  mt-24 md:mt-0">
          {navigation
            .filter((item) => item.footer)
            .map((item) => (
              <a
                key={item.name}
                href={item.href}
                target={item.target}
                className="inline-flex items-center text-white font-bold px-4 cursor-pointer"
                rel="noreferrer"
              >
                {item.name}
              </a>
            ))}
        </div>
        <address className="text-sm font-light">
          <p>2969 CONEY ISLAND AVE, BROOKLYN NY 11235</p>
          <p>1702 AVENUE Z, BROOKLYN NY 11235</p>
        </address>
      </div>
    </footer>
  );
};
