/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { ContactForm } from "../../components/ContactForm";
import FireProtectionBack from "../../assets/img/fire-protection-background.png";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Sprinkler } from "../../icons/services/sprinkler";
import { DrySystems } from "../../icons/services/dry-systems";
import { PreActionsSystems } from "../../icons/services/pre-action-systems";
import { DelugeSystems } from "../../icons/services/deluge-systems";
import { Waterless } from "../../icons/services/waterless";
import { Helmet } from "react-helmet-async";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const accordionData = [
  {
    id: 0,
    title: "Wet Sprinkler Systems",
    text: "Wet sprinkler systems are fire protection systems that utilize water as the primary extinguishing agent. These systems are designed to respond quickly to fires by releasing water through sprinkler heads when heat triggers them. During the installation process, we carefully assess the layout and requirements of your property to design a wet sprinkler system that meets the specific fire protection needs. We install high-quality sprinkler heads, pipes, valves, and control panels, all compliant with relevant codes and standards. Regular maintenance of wet sprinkler systems is vital to ensure their reliability. Our technicians conduct routine inspections, including testing sprinkler heads, checking valves and piping, and verifying the functionality of control panels. If any issues are detected, we provide prompt repairs and necessary replacements to keep the system in optimal condition. In addition to installation and maintenance, we offer expert troubleshooting and repair services for wet sprinkler systems. Our team is available 24/7 to respond to emergency situations and provide timely assistance when needed.",
    icon: <Sprinkler />,
  },
  {
    id: 1,
    title: "Dry Systems",
    text: "In the field of fire protection, a dry system is a type of fire sprinkler system. Unlike wet sprinkler systems, which are constantly filled with water, dry sprinkler systems contain pressurized air or nitrogen in the pipes until a fire is detected. When a fire activates a sprinkler head, the air is released, allowing the water to flow and extinguish the fire. Dry systems are commonly used in areas where freezing temperatures can occur, such as unheated warehouses or parking garages.",
    icon: <DrySystems />,
  },
  {
    id: 2,
    title: "Pre-Action Systems",
    text: "A pre-action system is a type of fire protection system that combines elements of both wet and dry systems. It is commonly used in areas where accidental activation of sprinklers could result in water damage, such as data centers, museums, or areas with valuable assets. A pre-action system is designed to provide an additional layer of protection and prevent accidental sprinkler discharge. It uses a combination of fire detection and water control mechanisms.",
    icon: <PreActionsSystems />,
  },
  {
    id: 3,
    title: "Deluge Systems",
    text: "A deluge system is a type of fire protection system designed to quickly and efficiently extinguish fires in high-hazard areas where a rapid release of water or suppression agent is necessary. Unlike traditional sprinkler systems, which activate individually in response to heat, deluge systems activate all the sprinkler heads simultaneously when a fire is detected.",
    icon: <DelugeSystems />,
  },
  {
    id: 4,
    title: "Waterless/Clean Agent Systems",
    text: "Waterless or clean agent systems are fire suppression systems that utilize specialized extinguishing agents other than water to suppress fires. These systems are commonly used in areas where water-based suppression methods are not suitable or where water damage needs to be minimized, such as data centers, server rooms, museums, or valuable equipment areas. Clean agent systems work by reducing the oxygen concentration within the protected space, interrupting the chemical chain reaction necessary for combustion, or cooling the fire to extinguish it. Here are some common types of clean agents used in waterless fire suppression systems.",
    icon: <Waterless />,
  },
];

export const FireProtectionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      <Helmet>
        <title>Fire protection services in NYC </title>
        <meta
          name="description"
          content="Commercial and industrial fire protections services in Brooklyn, Queens, Bronx, Manhatten, Staten Island. Call us for a quick plumbing solutions."
        />
      </Helmet>
      <img src={FireProtectionBack} alt="Vital Service" className="w-full" />
      <div className="bg-[#F9FAFB]">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <h1 className="text-primary font-bold text-4xl text-center mb-12">
            VITAL FIRE PROTECTION SERVICES
          </h1>
          <div className="flex flex-col  px-5 w-full ">
            {accordionData.map((item) => (
              <>
                <Accordion
                  key={item.id}
                  open={open === item.id}
                  icon={<Icon id={item.id} open={open} />}
                >
                  <AccordionHeader
                    onClick={() => handleOpen(item.id)}
                    className="py-4 text-xl border-b-2 border-grey-2"
                  >
                    <h2 className="flex items-center gap-4 text-blue-2">
                      {item.icon} {item.title}
                    </h2>
                  </AccordionHeader>
                  <AccordionBody className="text-base">
                    {item.text}
                  </AccordionBody>
                </Accordion>
              </>
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
