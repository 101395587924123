// import VideoPlayer from "react-background-video-player";
import { Banner } from "../../components/Banner";
import { ServiceCards } from "../../components/ServiceCards";
// import { SecondBannerAndCards } from "../../components/SecondBannerAndCards";
import { OurProjects } from "../../components/OurProjects";
import { OurClients } from "../../components/OurClients";
import { AboutCompany } from "../../components/AboutCompany";
import { FAQ } from "../../components/FAQ";
import { ContactForm } from "../../components/ContactForm";
import { Helmet } from "react-helmet-async";

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Commercial plumbing in Brooklyn, NY | Vital Plumbing</title>
        <meta
          name="description"
          content="Vital Plumbing performs plumbing, heating, and fire suppression work since 2001. Vital works with a number of city and state agencies including NYCSCA, NYCHA, DPR, HRPT, NYNJPA, DDC, FDNY, DASNY, OGS."
        />
      </Helmet>
      <Banner />
      <ServiceCards />
      {/* <SecondBannerAndCards /> */}
      <OurProjects />
      <OurClients />
      <AboutCompany />
      <FAQ />
      <ContactForm />
    </>
  );
};
