import * as React from "react";
export const PlumbingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}
  >
    <path
      fill="#5DC5F1"
      d="M72 39.91C72 57.578 57.758 72 40.27 72 22.603 72 8 57.578 8 39.91 8 22.423 22.422 8 39.91 8 57.758 8.18 71.82 22.242 72 39.91ZM39.91 19.178l-.541 1.081c-1.803 6.13-4.687 11.718-9.014 16.586-1.442 1.623-2.524 3.606-3.245 5.77-3.065 9.013 1.622 16.765 10.997 18.027 9.014 1.262 16.406-5.589 15.504-14.603-.36-3.064-1.262-5.769-3.245-8.112-4.507-5.589-8.293-11.538-10.456-18.75Z"
    />
    <path
      fill="#5CC2ED"
      d="M40.097 31.799c1.983 3.425 4.327 5.95 6.13 8.834 1.803 2.704 3.245 5.769 2.343 9.374-1.081 4.327-5.949 6.851-11.177 5.589-4.507-1.082-7.031-5.048-6.13-9.555.36-1.983 1.262-3.606 2.344-5.228 2.163-3.065 4.507-5.589 6.49-9.014Z"
    />
  </svg>
);
