/* eslint-disable no-unused-vars */
export const DOBRequirement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M28.7842 12.0944C28.2644 12.0944 27.843 11.673 27.843 11.1532V3.9375H14.3528C12.7959 3.9375 11.5293 5.20414 11.5293 6.76103V17.2827C11.8394 17.2546 12.1532 17.2395 12.4705 17.2395C15.6785 17.2395 18.5503 18.7062 20.4508 21.0042H30.6666C31.1863 21.0042 31.6077 21.4256 31.6077 21.9453C31.6077 22.4651 31.1863 22.8865 30.6666 22.8865H21.6907C22.279 24.0345 22.6584 25.3063 22.7802 26.6512H30.6666C31.1863 26.6512 31.6077 27.0726 31.6077 27.5924C31.6077 28.1122 31.1863 28.5336 30.6666 28.5336H22.7802C22.4987 31.6419 20.838 34.358 18.4161 36.063H33.1764C34.7333 36.063 35.9999 34.7964 35.9999 33.2395V12.0944H28.7842ZM30.6666 17.2395H16.8626C16.3429 17.2395 15.9215 16.8181 15.9215 16.2983C15.9215 15.7785 16.3429 15.3571 16.8626 15.3571H30.6666C31.1863 15.3571 31.6077 15.7785 31.6077 16.2983C31.6077 16.8181 31.1863 17.2395 30.6666 17.2395Z"
      fill="#0253B3"
    />
    <path
      d="M29.7246 4.48828V10.2117H35.4477L29.7246 4.48828Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 30.083C19 28.1219 17.8773 25.7124 17.1215 24.3125L16.4485 23.161C16.5115 23.6034 16.4866 24.0542 16.3752 24.4867C16.2289 24.8663 16.005 25.2103 15.7181 25.4963C15.6855 24.4979 15.394 23.5258 14.873 22.6772C14.7741 22.5288 14.5221 22.1247 14.2415 21.7644C13.6878 21.0508 13.0035 20.4518 12.2258 20C12.498 20.5692 12.6614 21.1854 12.7074 21.816C12.6851 22.5578 12.4522 23.003 11.3519 24.4545C10.2516 25.9059 10.5227 28.0703 10.5227 28.0703C10.4348 27.7679 10.315 27.4759 10.1655 27.1994C9.87843 26.6349 8.70794 25.9705 8.63458 25.9317C8.66647 26.0124 9.19272 27.3381 8.47511 28.238C7.56296 29.3895 6.83259 30.2475 7.03352 31.9699C7.28867 34.1601 9.75405 34.7729 10.8863 34.9374H10.8895C11.2307 34.989 11.4476 34.9987 11.4476 34.9987C10.7022 34.7407 10.2774 33.7924 10.2187 33.2708C10.1731 32.9737 10.1917 32.6701 10.2732 32.381C10.3547 32.0918 10.4971 31.8239 10.6908 31.5958C10.8426 31.3855 11.4036 30.8216 11.5924 30.6281C12.0875 30.2006 12.4323 29.6225 12.5754 28.9802C12.6524 28.5311 12.6662 28.0733 12.6165 27.6203C13.1414 27.9571 13.5861 28.4072 13.9187 28.9383C14.1781 29.3544 14.3221 29.8335 14.3356 30.3252C14.4768 30.1838 14.5871 30.0139 14.6593 29.8266C14.7142 29.6121 14.726 29.3887 14.694 29.1695C14.694 29.1695 15.9876 30.9758 15.9513 32.5809C15.9057 34.5923 14.6755 34.9977 14.6736 35C14.6867 34.9968 19 34.4529 19 30.083Z"
      fill="#0253B3"
    />
  </svg>
);
