/* eslint-disable no-unused-vars */
export const PTAC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M22.068 31.8966H28.2749C28.5599 31.8966 28.7922 31.6643 28.7922 31.3793V22.069C28.7922 21.784 28.5599 21.5518 28.2749 21.5518H22.068C21.783 21.5518 21.5508 21.784 21.5508 22.069V31.3793C21.5508 31.6643 21.783 31.8966 22.068 31.8966ZM27.2404 22.5862H28.2749V30.8621H27.2404V22.5862ZM25.6887 22.5862H26.7232V30.8621H25.6887V22.5862ZM23.6197 22.5862H24.6542V30.8621H23.6197V22.5862ZM22.068 22.5862H23.1025V30.8621H22.068V22.5862Z"
      fill="#0253B3"
    />
    <path
      d="M16.3793 29.8275H11.7241C10.8686 29.8275 10.1724 29.1313 10.1724 28.2757V24.6551H13.2759V9.13781H5V24.6551H6.03448V30.3447C6.03448 31.7707 7.19466 32.9309 8.62069 32.9309H16.3793V34.9999H17.4138V7.06885H16.3793V29.8275ZM12.2414 17.4137H6.03448V16.3792H12.2414V17.4137ZM12.2414 15.3447H6.03448V14.3102H12.2414V15.3447ZM11.2069 10.1723C12.0624 10.1723 12.7586 10.8685 12.7586 11.724C12.7586 12.5795 12.0624 13.2757 11.2069 13.2757C10.3514 13.2757 9.65517 12.5795 9.65517 11.724C9.65517 10.8685 10.3514 10.1723 11.2069 10.1723ZM5.51724 11.724C5.51724 10.8685 6.21345 10.1723 7.06897 10.1723C7.92448 10.1723 8.62069 10.8685 8.62069 11.724C8.62069 12.5795 7.92448 13.2757 7.06897 13.2757C6.21345 13.2757 5.51724 12.5795 5.51724 11.724ZM8.62069 31.8964C7.76517 31.8964 7.06897 31.2002 7.06897 30.3447V24.6551H9.13793V28.2757C9.13793 29.7018 10.2981 30.8619 11.7241 30.8619H16.3793V31.8964H8.62069Z"
      fill="#0253B3"
    />
    <path
      d="M24.6543 16.3794H25.6888V20.5173H24.6543V16.3794Z"
      fill="#0253B3"
    />
    <path
      d="M32.9316 7.06885H33.9661V34.9999H32.9316V7.06885Z"
      fill="#0253B3"
    />
    <path
      d="M15.3457 5V6.03448H15.8629H17.9319H32.4147H34.4836H35.0009V5H15.3457Z"
      fill="#0253B3"
    />
    <path
      d="M18.4492 33.9654H31.8975V7.06885H18.4492V33.9654ZM20.5182 22.0688C20.5182 21.2133 21.2144 20.5171 22.0699 20.5171H23.6216V16.3792H22.5871V15.3447H27.7596V16.3792H26.7251V20.5171H28.2768C29.1323 20.5171 29.8285 21.2133 29.8285 22.0688V31.3792C29.8285 32.2347 29.1323 32.9309 28.2768 32.9309H22.0699C21.2144 32.9309 20.5182 32.2347 20.5182 31.3792V22.0688Z"
      fill="#0253B3"
    />
    <path
      d="M7.06802 12.2415C7.35369 12.2415 7.58526 12.0099 7.58526 11.7243C7.58526 11.4386 7.35369 11.207 7.06802 11.207C6.78236 11.207 6.55078 11.4386 6.55078 11.7243C6.55078 12.0099 6.78236 12.2415 7.06802 12.2415Z"
      fill="#0253B3"
    />
    <path
      d="M11.2067 12.2415C11.4924 12.2415 11.7239 12.0099 11.7239 11.7243C11.7239 11.4386 11.4924 11.207 11.2067 11.207C10.921 11.207 10.6895 11.4386 10.6895 11.7243C10.6895 12.0099 10.921 12.2415 11.2067 12.2415Z"
      fill="#0253B3"
    />
  </svg>
);
