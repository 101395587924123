/* eslint-disable no-unused-vars */
export const SplitDuctlessSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M7.86667 20.7616V27.5291C7.86667 32.1611 11.6347 35.9291 16.2667 35.9291H20C21.2152 35.9291 22.2415 35.1464 22.6281 34.0624H31.2C32.744 34.0624 34 32.8064 34 31.2624C34 29.7184 32.744 28.4624 31.2 28.4624H27.4667C26.9517 28.4624 26.5333 28.044 26.5333 27.5291V26.5335H32.1333V15.3335H19.0667V26.5335H24.6667V27.5291C24.6667 29.073 25.9227 30.3291 27.4667 30.3291H31.2C31.715 30.3291 32.1333 30.7474 32.1333 31.2624C32.1333 31.7773 31.715 32.1957 31.2 32.1957H22.6281C22.2415 31.1117 21.2152 30.3291 20 30.3291H16.2667C15.7517 30.3291 15.3333 29.9107 15.3333 29.3957V26.3616C16.4174 25.975 17.2 24.9487 17.2 23.7335C17.2 22.1895 15.944 20.9335 14.4 20.9335C12.856 20.9335 11.6 22.1895 11.6 23.7335C11.6 24.9487 12.3826 25.975 13.4667 26.3616V29.3957C13.4667 30.9397 14.7227 32.1957 16.2667 32.1957H20C20.515 32.1957 20.9333 32.6141 20.9333 33.1291C20.9333 33.644 20.515 34.0624 20 34.0624H16.2667C12.6646 34.0624 9.73333 31.1312 9.73333 27.5291V20.7616C10.8174 20.375 11.6 19.3487 11.6 18.1335C11.6 16.5895 10.344 15.3335 8.8 15.3335C7.25602 15.3335 6 16.5895 6 18.1335C6 19.3487 6.78263 20.375 7.86667 20.7616ZM22.8 18.1335H28.4V23.7335H22.8V18.1335Z"
      fill="#0253B3"
    />
    <path
      d="M19.0664 13.4668H32.1331V4.07129H19.0664V13.4668ZM26.5331 9.73351H28.3997V11.6002H26.5331V9.73351ZM22.7997 9.73351H24.6664V11.6002H22.7997V9.73351Z"
      fill="#0253B3"
    />
    <path d="M24.666 20H26.5327V21.8667H24.666V20Z" fill="#0253B3" />
  </svg>
);
