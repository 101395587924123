/* eslint-disable no-unused-vars */
export const SplitSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M10.2843 7.4285C10.5999 7.4285 10.8557 7.17266 10.8557 6.85707C10.8557 6.54148 10.5999 6.28564 10.2843 6.28564C9.96873 6.28564 9.71289 6.54148 9.71289 6.85707C9.71289 7.17266 9.96873 7.4285 10.2843 7.4285Z"
      fill="#0253B3"
    />
    <path
      d="M14.2852 4H6.28516V16.5714H14.2852V4ZM10.2852 5.14286C10.6242 5.14286 10.9556 5.2434 11.2376 5.43177C11.5195 5.62013 11.7392 5.88787 11.869 6.20111C11.9987 6.51436 12.0326 6.85904 11.9665 7.19158C11.9004 7.52412 11.7371 7.82958 11.4973 8.06933C11.2576 8.30907 10.9521 8.47234 10.6196 8.53849C10.2871 8.60464 9.94237 8.57069 9.62913 8.44094C9.31588 8.31119 9.04815 8.09146 8.85978 7.80955C8.67141 7.52764 8.57087 7.1962 8.57087 6.85714C8.57087 6.40249 8.75148 5.96645 9.07297 5.64496C9.39446 5.32347 9.8305 5.14286 10.2852 5.14286ZM12.5709 14.2857H7.99944V13.1429H12.5709V14.2857ZM12.5709 12H7.99944V10.8571H12.5709V12Z"
      fill="#0253B3"
    />
    <path
      d="M29.714 7.4285C30.0296 7.4285 30.2854 7.17266 30.2854 6.85707C30.2854 6.54148 30.0296 6.28564 29.714 6.28564C29.3984 6.28564 29.1426 6.54148 29.1426 6.85707C29.1426 7.17266 29.3984 7.4285 29.714 7.4285Z"
      fill="#0253B3"
    />
    <path
      d="M33.7148 4H25.7148V16.5714H33.7148V4ZM29.7148 5.14286C30.0539 5.14286 30.3853 5.2434 30.6672 5.43177C30.9492 5.62013 31.1689 5.88787 31.2986 6.20111C31.4284 6.51436 31.4623 6.85904 31.3962 7.19158C31.33 7.52412 31.1668 7.82958 30.927 8.06933C30.6873 8.30907 30.3818 8.47234 30.0493 8.53849C29.7167 8.60464 29.3721 8.57069 29.0588 8.44094C28.7456 8.31119 28.4778 8.09146 28.2895 7.80955C28.1011 7.52764 28.0006 7.1962 28.0006 6.85714C28.0006 6.40249 28.1812 5.96645 28.5027 5.64496C28.8242 5.32347 29.2602 5.14286 29.7148 5.14286ZM32.0006 14.2857H27.4291V13.1429H32.0006V14.2857ZM32.0006 12H27.4291V10.8571H32.0006V12Z"
      fill="#0253B3"
    />
    <path
      d="M19.9992 26.8577C20.3148 26.8577 20.5706 26.6019 20.5706 26.2863C20.5706 25.9707 20.3148 25.7148 19.9992 25.7148C19.6836 25.7148 19.4277 25.9707 19.4277 26.2863C19.4277 26.6019 19.6836 26.8577 19.9992 26.8577Z"
      fill="#0253B3"
    />
    <path
      d="M23.998 23.4287H15.998V36.0001H23.998V23.4287ZM19.998 24.5716C20.3371 24.5716 20.6685 24.6721 20.9505 24.8605C21.2324 25.0488 21.4521 25.3166 21.5818 25.6298C21.7116 25.9431 21.7455 26.2878 21.6794 26.6203C21.6132 26.9528 21.45 27.2583 21.2102 27.498C20.9705 27.7378 20.665 27.9011 20.3325 27.9672C19.9999 28.0333 19.6553 27.9994 19.342 27.8696C19.0288 27.7399 18.761 27.5202 18.5727 27.2383C18.3843 26.9563 18.2838 26.6249 18.2838 26.2859C18.2838 25.8312 18.4644 25.3952 18.7859 25.0737C19.1074 24.7522 19.5434 24.5716 19.998 24.5716ZM22.2838 33.7144H17.7123V32.5716H22.2838V33.7144ZM22.2838 31.4287H17.7123V30.2859H22.2838V31.4287Z"
      fill="#0253B3"
    />
    <path d="M15.4277 10.2861H24.5706V11.429H15.4277V10.2861Z" fill="#0253B3" />
    <path
      d="M17.2104 22.2858H15.9361L13.6504 17.7144H14.9247L17.2104 22.2858Z"
      fill="#0253B3"
    />
    <path
      d="M26.3549 17.7144L24.0692 22.2858H22.7949L25.0806 17.7144H26.3549Z"
      fill="#0253B3"
    />
  </svg>
);
