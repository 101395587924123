/* eslint-disable no-unused-vars */
export const AirConditioningSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M19.8347 18.3333C19.5014 17.6666 18.6681 17.6666 18.3347 18.3333L18.0014 18.8333C17.8347 18.9999 17.6681 19.1666 17.5014 19.3333L16.3347 19.6666C16.1681 19.3333 16.0014 18.9999 15.8347 18.8333L17.0014 17.1666C17.5014 16.3333 17.0014 15.3333 16.0014 15.1666C15.1681 14.9999 14.1681 14.9999 13.3347 15.3333C12.8347 15.6666 12.8347 16.4999 13.3347 16.6666L13.8347 16.9999L14.3347 17.4999L14.6681 18.6666C14.3347 18.8333 14.0014 18.9999 13.8347 19.1666L12.1681 17.9999C11.3347 17.4999 10.3347 17.9999 10.1681 18.9999C10.0014 19.9999 10.0014 20.9999 10.3347 21.6666C10.6681 22.3333 11.5014 22.3333 11.8347 21.6666L12.1681 21.1666L12.6681 20.6666L13.8347 20.3333C14.0014 20.6666 14.1681 20.9999 14.3347 21.1666L13.1681 22.8333C12.6681 23.6666 13.1681 24.6666 14.1681 24.8333C15.0014 24.9999 16.0014 24.9999 16.6681 24.6666C17.3347 24.3333 17.3347 23.4999 16.6681 23.1666L16.1681 22.8333C16.0014 22.6666 15.8347 22.4999 15.6681 22.3333L15.3347 21.1666C15.6681 20.9999 16.0014 20.8333 16.1681 20.6666L17.8347 21.8333C18.6681 22.3333 19.6681 21.8333 19.8347 20.8333C20.1681 19.9999 20.1681 18.9999 19.8347 18.3333Z"
      fill="#0253B3"
    />
    <path
      d="M35.0007 6.6665H5.00065C4.00065 6.6665 3.33398 7.33317 3.33398 8.33317V31.6665C3.33398 32.6665 4.00065 33.3332 5.00065 33.3332H35.0007C36.0007 33.3332 36.6673 32.6665 36.6673 31.6665V8.33317C36.6673 7.33317 36.0007 6.6665 35.0007 6.6665ZM15.0007 28.3332C10.334 28.3332 6.66732 24.6665 6.66732 19.9998C6.66732 15.3332 10.334 11.6665 15.0007 11.6665C19.6673 11.6665 23.334 15.3332 23.334 19.9998C23.334 24.6665 19.6673 28.3332 15.0007 28.3332ZM33.334 28.3332H26.6673V24.9998H33.334V28.3332ZM33.334 21.6665H26.6673V18.3332H33.334V21.6665ZM33.334 14.9998H26.6673V11.6665H33.334V14.9998Z"
      fill="#0253B3"
    />
  </svg>
);
