/* eslint-disable react/prop-types */
import { useState } from "react";
import { Button } from "../Button";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const AboutCompany = () => {
  const [address, setAddress] = useState(0);
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mx-auto max-w-7xl px-5 py-12 md:py-32" id="about">
      <h2 className="text-primary font-bold text-4xl mb-10">ABOUT COMPANY</h2>
      <h4 className="text-2xl font-normal  text-blue-2 mb-2 pb-2 border-b-2 border-blue-2 w-full">
        Mission statement
      </h4>
      <p className="text-xl font-light  text-blue-2 mb-12 mt-8">
        At Vital Plumbing, our mission is to provide exceptional plumbing
        services with integrity, reliability, and professionalism. We strive to
        exceed customer expectations by delivering high-quality solutions that
        address their unique plumbing needs. Our team of skilled and certified
        plumbers is dedicated to ensuring the utmost satisfaction of our
        clients, whether it`s for commercial, institutional, or industrial
        projects. We aim to build long-term relationships based on trust and
        transparency, consistently delivering efficient and cost-effective
        plumbing services. With a focus on continuous improvement and staying
        up-to-date with the latest industry advancements, we are committed to
        being a trusted partner for all plumbing requirements.
      </p>
      <div className="flex flex-col md:flex-row pt-4 md:pt-8 pb-16 gap-0 md:gap-16">
        <div className="w-full md:w-1/2">
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="py-4 text-xl"
            >
              Company story
            </AccordionHeader>
            <AccordionBody className="text-base">
              At Vital Plumbing, we strive to deliver the same level of
              attention to detail and quality of service to all our clients,
              regardless of the size and complexity of the project. Whether we
              are replacing a faucet, upgrading a home heating system, or
              installing a complete plumbing, heating, and sprinkler system in a
              30-story building, our commitment to excellence remains unchanged.
              By choosing Vital Plumbing, you can be assured that you are
              getting the very best plumbing services available. Today, Vital’s
              workforce numbers over 35 skilled professionals. As a
              comprehensive service provider, Vital offers a wide range of
              plumbing installation services. From minor repairs to large-scale
              residential and commercial new construction projects, we have the
              expertise to handle jobs of any size, type, or complexity.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="py-4 text-xl"
            >
              Team
            </AccordionHeader>
            <AccordionBody className="text-base">
              Presently, Vital boasts an accomplished team of more than 35
              proficient experts. Functioning as an all-inclusive service
              provider, Vital extends a diverse array of plumbing installation
              services. Whether it&#39;s addressing minor repairs or undertaking
              extensive ventures in residential and commercial new
              constructions, we possess the proficiency to tackle projects of
              any magnitude, category, or intricacy.
            </AccordionBody>
          </Accordion>
        </div>
        <div className="w-full md:w-1/2">
          <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
            <AccordionHeader
              onClick={() => handleOpen(3)}
              className="py-4 text-xl"
            >
              Licenses
            </AccordionHeader>
            <AccordionBody className="text-base">
              As a comprehensive service provider, we offer a wide range of
              plumbing installation services. Our team includes two full-time
              Licensed Master Plumbers and one Licensed Fire Suppression
              Contractor, further enhancing our expertise and capabilities.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
            <AccordionHeader
              onClick={() => handleOpen(4)}
              className="py-4 text-xl"
            >
              Certificates
            </AccordionHeader>
            <AccordionBody className="text-base">
              Vital’s team hold a number of industry specific certificates
              including: Gas Operator Tasks 86/87, Gas Pipe Welding High/Low
              Pressure, , DOB Full Gas work, FDNY S12/S13, OSHA 40/60
            </AccordionBody>
          </Accordion>
        </div>
      </div>

      <div className="my-8 text-blue-2 flex flex-col md:flex-row justify-between w-full items-center">
        <ul className="list-disc ml-4">
          <li
            className="border-b border-blue-2 py-2 cursor-pointer"
            onClick={() => setAddress(0)}
          >
            2969 CONEY ISLAND AVE, BROOKLYN NY 11235
          </li>
          <li
            className="border-b border-blue-2 py-2 cursor-pointer"
            onClick={() => setAddress(1)}
          >
            1702 AVENUE Z, BROOKLYN NY 11235
          </li>
        </ul>
        <a href="#contactForm">
          <Button variant="movingBlue" className="h-fit mt-4 md:mt-0">
            BOOK APPOINTMENT
          </Button>
        </a>
      </div>
      {!address ? (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.0999094568397!2d-73.96149983005328!3d40.583550041602635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2445d568da421%3A0x1b796c298ff01d46!2s2969%20Coney%20Island%20Ave%2C%20Brooklyn%2C%20NY%2011235%2C%20USA!5e0!3m2!1sen!2spl!4v1685874346167!5m2!1sen!2spl"
          width="100%"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ) : (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.896621835545!2d-73.95466212344591!3d40.58803724519458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24463f345a0e5%3A0xac4775e4098519f7!2s1702%20Avenue%20Z%2C%20Brooklyn%2C%20NY%2011235%2C%20USA!5e0!3m2!1sen!2spl!4v1685874425568!5m2!1sen!2spl"
          width="100%"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      )}
    </div>
  );
};
