import { Card } from "../../components/Card";
import { AirConditionerIcon } from "../../icons/airConditionerIcon";
import { PlumbingIcon } from "../../icons/plumbingIcon";
import { SprinklersIcon } from "../../icons/sprinklersIcon";
import { ViolationsIcon } from "../../icons/violationsIcon";

import BackgroundImage from "../../assets/img/back.png";
import { useNavigate } from "react-router-dom";

export const ServiceCards = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`bg-primary pb-10 `}
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
        <div className="flex flex-wrap items-stretch gap-8 flex-col md:flex-row ">
          <Card
            className="bg-primary flex items-center flex-col py-12 px-4 shadow-card cursor-pointer"
            onClick={() => navigate("plumbing")}
          >
            <PlumbingIcon />
            <h2 className="text-2xl font-bold text-center text-blue-1 mt-2">
              VITAL PLUMBING <br /> SERVICES
            </h2>
          </Card>
          <Card
            className="bg-primary flex items-center flex-col py-12 px-4 shadow-card cursor-pointer"
            onClick={() => navigate("fire-protection")}
          >
            <SprinklersIcon />
            <h2 className="text-2xl font-bold text-center text-red-1  mt-2">
              VITAL FIRE <br /> PROTECTION
            </h2>
          </Card>
          <Card
            className="bg-primary flex items-center flex-col py-12 px-4 shadow-card cursor-pointer"
            onClick={() => navigate("heating-cooling-service")}
          >
            <AirConditionerIcon />
            <h2 className="text-2xl font-bold text-center text-yellow-1  mt-2">
              VITAL HEATING <br /> AND COOLING
            </h2>
          </Card>
          <Card
            className="bg-primary flex items-center flex-col py-12 px-4 shadow-card cursor-pointer"
            onClick={() => navigate("inspection")}
          >
            <ViolationsIcon />
            <h2 className="text-2xl font-bold text-center text-white  mt-2">
              VITAL VIOLATIONS <br /> & INSPECTIONS
            </h2>
          </Card>
        </div>
      </div>
    </div>
  );
};
