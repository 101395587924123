/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
// import Banner from "../../assets/img/projects2-banner.png";
import { Helmet } from "react-helmet-async";

import cardImg5 from "../../assets/img/card5.png";

import SimpleImageSlider from "react-simple-image-slider";

const images = [{ url: cardImg5 }];

export const PS575 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Academy of american studies | Vital Plumbing Projects</title>
        <meta
          name="description"
          content="Check out our work at Academy of American Studies. Your plumbing and HVAC solution in NY. Call us for a free quote."
        />
      </Helmet>
      <div
        className="flex items-center justify-center w-screen overflow-hidden bg-cover md:h-[600px] bg-center"
        style={{
          backgroundImage: `url(${cardImg5})`,
          minHeight: 300,
          width: "100%",
        }}
      >
        <div className="pt-16">
          <h1 className="text-2xl md:text-6xl text-white font-bold">
            P.S. 575 ACADEMY OF AMERICAN STUDIES
          </h1>
        </div>
      </div>
      <div className="bg-[#F9FAFB] relative">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-col md:flex-row md:my-12 gap-8">
                <div className="w-full md:w-1/2 ">
                  <p className="text-base text-grey-1 font-light">Category:</p>
                  <p className="text-xl">Educational</p>
                  <p className="text-base text-grey-1 font-light">Location:</p>
                  <p className="text-xl">Queens, NY</p>
                  <p className="text-base text-grey-1 font-light">
                    Year Built:
                  </p>
                  <p className="text-xl">2022</p>
                  <p className="text-base text-grey-1 font-light mt-4">Size:</p>
                  <p className="text-xl">120,000 SF</p>
                </div>
                <div className="w-full md:w-1/2  text-base font-light">
                  The new four-story building will house 969 students and
                  include a visual art room, music room, science labs and a
                  medical suite, according to the Department of Education.
                  Currently, there are about 909 students enrolled at Newcomers
                  High School and 1,008 enrolled at the Academy of American
                  Studies.
                </div>
              </div>

              <div className="w-full py-8">
                <SimpleImageSlider
                  width={"100%"}
                  height={800}
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
