/* eslint-disable no-unused-vars */
export const FireStandpipeSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M27.8286 19.3713H25.9863V24.4803H28.3038C31.05 24.4803 33.2842 22.246 33.2842 19.4998V14.771H27.8286V19.3713Z"
      fill="#0253B3"
    />
    <path
      d="M27.8281 11.0259H33.2838V13.0128H27.8281V11.0259Z"
      fill="#0253B3"
    />
    <path d="M26.2227 5H35.0003V9.26823H26.2227V5Z" fill="#0253B3" />
    <path
      d="M20.8786 13.5991H23.4935V11.8413H16.5059V13.5991H19.1208V15.6999H20.8786V13.5991Z"
      fill="#0253B3"
    />
    <path d="M17.4434 28.02H22.5543V29.7566H17.4434V28.02Z" fill="#0253B3" />
    <path
      d="M17.4434 31.5142H22.5543V34.9998H17.4434V31.5142Z"
      fill="#0253B3"
    />
    <path
      d="M12.1705 19.3713V14.771H6.71484V19.4998C6.71484 22.246 8.94907 24.4803 11.6953 24.4803H14.0127V19.3713H12.1705Z"
      fill="#0253B3"
    />
    <path
      d="M6.71484 11.0259H12.1705V13.0128H6.71484V11.0259Z"
      fill="#0253B3"
    />
    <path d="M5 5H13.7777V9.26823H5V5Z" fill="#0253B3" />
    <path
      d="M15.7129 17.3989H24.2873V26.3206H15.7129V17.3989Z"
      fill="#0253B3"
    />
  </svg>
);
