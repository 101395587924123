// import { useEffect } from "react";
// import { Routes, Route } from "react-router-dom";
// import { HomePage } from "./pages/HomePage";
import { Router } from "./Router";
import { Layout } from "./pages/Layout";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Layout>
        <Router />
        {/* <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/plumbing" element={<PlumbingPage />} />
        <Route path="/inspection" element={<InspectionPage />} />
        <Route path="/fire-protection" element={<FireProtectionPage />} />
        <Route
          path="/heating-cooling-service"
          element={<HeatingCoolingServicePage />}
        />
        <Route
          path="/nycsca-ps-129q-new-school"
          element={<NYCSCASchoolPage />}
        />
        <Route path="/nycsca-ps-19x-new-school" element={<NYCSCA19x />} />
        <Route path="/ps-464m-trinity-place" element={<PsTrinityPlace />} />
        <Route path="/rescue-company-2" element={<RescueCompany2 />} />
        <Route path="/ps-575-academy-of-american-studies" element={<PS575 />} />
        <Route path="/red-hook-houses" element={<RedHookHouses />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes> */}
      </Layout>
    </HelmetProvider>
  );
}

export default App;
