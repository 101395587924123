/* eslint-disable no-unused-vars */
export const LocalLawCompliance = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M17.6255 9.375C16.4193 9.375 15.438 8.39369 15.438 7.1875V0H2.98491C1.51596 -3.82127e-08 0.812998 0.891491 0.812998 2.01187L0.8125 30.3485C0.8125 31.3233 1.59586 32 2.72187 32H23.157C24.5957 32 25.188 31.1909 25.188 29.8773V9.375H17.6255ZM5.938 22.5H10.483C11.0007 22.5 11.4205 22.9198 11.4205 23.4375C11.4205 23.9552 11.0007 24.375 10.483 24.375H5.938C5.42025 24.375 5.0005 23.9552 5.0005 23.4375C5.0005 22.9198 5.42025 22.5 5.938 22.5ZM5.0005 18.4375C5.0005 17.9198 5.42025 17.5 5.938 17.5H19.688C20.2057 17.5 20.6255 17.9198 20.6255 18.4375C20.6255 18.9552 20.2057 19.375 19.688 19.375H5.938C5.42025 19.375 5.0005 18.9552 5.0005 18.4375ZM19.688 12.5C20.2057 12.5 20.6255 12.9198 20.6255 13.4375C20.6255 13.9553 20.2057 14.375 19.688 14.375H5.938C5.42025 14.375 5.0005 13.9553 5.0005 13.4375C5.0005 12.9198 5.42025 12.5 5.938 12.5H19.688Z"
      fill="#0253B3"
    />
    <path
      d="M17.3125 7.18736C17.3125 7.35967 17.4527 7.49986 17.625 7.49986H24.7696C24.5981 7.18324 24.3775 6.89242 24.1124 6.64161L18.086 0.940234C17.8521 0.718984 17.5916 0.534422 17.3126 0.388672V7.18736H17.3125Z"
      fill="#0253B3"
    />
  </svg>
);
