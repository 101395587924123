/* eslint-disable react/prop-types */
import classnames from "classnames";

export const Card = ({
  children,
  className,
  variant = "default",
  ...props
}) => {
  let buttonClasses = " flex-1 gap-2";

  switch (variant) {
    case "default":
      buttonClasses;
      break;
    case "moving":
      buttonClasses += ` inline-block relative overflow-hidden text-center shadow-card transition duration-200 border-2 border-transparent hover:shadow-none hover:-translate-x-2 hover:translate-y-2 ${className}`;
      break;
    case "moving-blue-shadow":
      buttonClasses += ` inline-block relative overflow-hidden text-center shadow-card-blue transition duration-200 border-transparent hover:shadow-none hover:-translate-x-2 hover:translate-y-2 ${className}`;
      break;
    default:
      buttonClasses;
  }
  return (
    <div className={classnames(buttonClasses, className)} {...props}>
      {children}
    </div>
  );
};
