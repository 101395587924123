/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { ContactForm } from "../../components/ContactForm";
import HeatingBack from "../../assets/img/heating-background.png";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { AirConditioningSystems } from "../../icons/services/air-conditioning-systems";
import { SplitSystems } from "../../icons/services/split-systems";
import { SplitDuctlessSystems } from "../../icons/services/split-ductless-systems";
import { StreamStation } from "../../icons/services/stream-station";
import { CirculatingPumps } from "../../icons/services/circulating-pumps";
import { PipeWelding } from "../../icons/services/pipe-welding";
import { PTAC } from "../../icons/services/ptac";
import { Pumps } from "../../icons/services/pumps";
import { Controls } from "../../icons/services/controls";
import { EmergencyService } from "../../icons/services/emergency-service";
import { Helmet } from "react-helmet-async";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const accordionData = [
  {
    id: 0,
    title: "Packaged Air Conditioning Systems",
    text: "Packaged air conditioning systems, also known as packaged units, are self-contained HVAC systems that provide both heating and cooling in a single package. These systems are commonly used in residential, commercial, and industrial settings where space is limited or where a centralized HVAC system may not be feasible.",
    icon: <AirConditioningSystems />,
  },
  {
    id: 1,
    title: "Split Systems",
    text: "Split systems, also known as split air conditioning systems, are a type of HVAC system that consists of two main components: an indoor unit and an outdoor unit. These units are connected by refrigerant lines and electrical wiring to provide cooling and, in some cases, heating to a space. Split systems are widely used in residential and commercial applications.",
    icon: <SplitSystems />,
  },
  {
    id: 2,
    title: "Split Ductless Systems",
    text: "Split ductless systems, also known as ductless mini-split systems, are a type of HVAC system that provides both heating and cooling to a space without the need for traditional ductwork. They consist of two main components: an outdoor unit and one or more indoor units. Split ductless systems are commonly used in residential and commercial applications, particularly in situations where ductwork installation is impractical or not desired.",
    icon: <SplitDuctlessSystems />,
  },
  {
    id: 3,
    title: "Steam Stations",
    text: "Steam stations, also known as steam generators or steam boilers, are systems designed to produce steam for various applications. They are commonly used in industrial settings, power plants, and other facilities that require a reliable and controlled source of steam for processes such as power generation, heating, sterilization, or cleaning.",
    icon: <StreamStation />,
  },
  {
    id: 4,
    title: "Circulating Pumps",
    text: "Circulating pumps, also known as circulation pumps or recirculation pumps, are devices used to circulate liquids within a closed system. They are commonly employed in heating, cooling, and plumbing systems to ensure a continuous flow of liquid and maintain consistent temperature and pressure throughout the system.",
    icon: <CirculatingPumps />,
  },
  {
    id: 5,
    title: "Pipe Welding",
    text: "Pipe welding is a process of joining two or more sections of pipe together using various welding techniques. It is commonly used in construction, manufacturing, and maintenance of pipelines, plumbing systems, and other structures that require the transportation of fluids or gases. Pipe welding is critical for creating strong and leak-free connections to ensure the integrity and functionality of the piping system.",
    icon: <PipeWelding />,
  },
  {
    id: 6,
    title: "PTAC/Water Source Heat Pumps",
    text: "PTAC stands for Packaged Terminal Air Conditioner. It is a type of self-contained heating and cooling system commonly used in hotels, motels, apartments, condominiums, and other individual room applications. PTAC units are designed to be mounted through a wall or in a window, providing heating and cooling to a single space.",
    icon: <PTAC />,
  },
  {
    id: 7,
    title: "Pumps",
    text: "Heat pumps are heating and cooling systems that can efficiently transfer heat from one location to another. They are designed to provide both heating and cooling functions, making them a versatile and energy-efficient alternative to traditional heating and cooling systems.",
    icon: <Pumps />,
  },
  {
    id: 8,
    title: "Controls",
    text: " HVAC controls refer to the devices, systems, and technologies used to monitor and regulate the operation of heating, ventilation, and air conditioning (HVAC) systems. These controls are crucial for maintaining desired indoor comfort conditions, improving energy efficiency, and ensuring proper functioning of the HVAC equipment. HVAC controls can range from simple thermostats to sophisticated building automation systems.",
    icon: <Controls />,
  },
  {
    id: 9,
    title: "*24/7 Emergency Service",
    text: "An HVAC emergency refers to a sudden and unexpected failure or malfunction of a heating, ventilation, and air conditioning (HVAC) system that requires immediate attention and resolution. HVAC emergencies can occur at any time and can lead to uncomfortable or even unsafe conditions within a building.",
    icon: <EmergencyService />,
  },
];

export const HeatingCoolingServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      <Helmet>
        <title>Commercial HVAC services in NYC</title>
        <meta
          name="description"
          content="Commercial and industrial HVAC services in Brooklyn, Queens, Bronx, Manhatten, Staten Island. Call us for a quick plumbing solutions."
        />
      </Helmet>
      <img src={HeatingBack} alt="Vital Service" className="w-full" />
      <div className="bg-[#F9FAFB]">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <h1 className="text-primary font-bold text-4xl text-center mb-12">
            VITAL HEATING AND COOLING SERVICES
          </h1>
          <div className="flex flex-col  px-5 w-full ">
            {accordionData.map((item) => (
              <>
                <Accordion
                  key={item.id}
                  open={open === item.id}
                  icon={<Icon id={item.id} open={open} />}
                >
                  <AccordionHeader
                    onClick={() => handleOpen(item.id)}
                    className="py-4 text-xl border-b-2 border-grey-2"
                  >
                    <h2 className="flex items-center gap-4 text-blue-2">
                      {item.icon} {item.title}
                    </h2>
                  </AccordionHeader>
                  <AccordionBody className="text-base">
                    {item.text}
                  </AccordionBody>
                </Accordion>
              </>
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
