// import { Button } from "../../components/Button";
import bannerVideo from "../../assets/img/bannerVideo.mp4";
import { Ticker } from "../Ticker";

export const Banner = () => {
  return (
    <div className="relative flex">
      <div className="bg-black w-full h-full absolute opacity-20"></div>
      <div className=" h-[700px] w-full">
        <video
          // playsInline
          autoPlay
          muted
          loop
          className="bg-cover w-full object-cover h-[700px] z-10"
        >
          <source
            src={
              // "https://static.videezy.com/system/resources/previews/000/020/853/original/clip9_1.mp4"
              bannerVideo
            }
          />
        </video>
      </div>
      <div
        className="w-full h-full absolute flex justify-start items-center"
        id="services"
      >
        <div className="mx-auto w-full max-w-7xl px-5">
          <h1 className="text-center md:text-left text-3xl pt-24 md:pt-0 md:text-5xl  text-white font-bold max-w-4xl ">
            YOUR VITAL CONNECTION TO COMMERCIAL PLUMBING IN BROOKLYN, NY
          </h1>
          <div className="flex flex-col md:flex-row justify-between mt-12 gap-4 flex-wrap">
            <div className="flex flex-col items-center">
              <Ticker
                className="text-white text-xl md:text-6xl"
                end={20}
                suffix="+"
                // duration={5}
              />
              <h4 className="text-white text-base md:text-2xl">
                YEARS SERVING NYC
              </h4>
            </div>
            <div className="w-[1px] bg-white h-22 pt-2 hidden md:flex" />
            <div className="flex flex-col items-center">
              <Ticker
                className="text-white text-xl md:text-6xl"
                end={1700}
                suffix="+"
                // duration={5}
              />
              <h4 className="text-white text-base md:text-2xl">
                ISSUED PERMITS
              </h4>
            </div>
            <div className="w-[1px] bg-white h-22 pt-2 hidden md:flex" />

            <div className="flex flex-col items-center">
              <Ticker
                className="text-white text-xl md:text-6xl"
                end={35}
                suffix="+"
                // duration={5}
              />
              <h4 className="text-white text-base md:text-2xl">
                SKILLED TECHNICIANS
              </h4>
            </div>
            <div className="w-[1px] bg-white h-22 pt-2 hidden md:flex" />

            <div className="flex flex-col items-center">
              <Ticker
                className="text-white text-xl md:text-6xl"
                end={24}
                suffix="/7"
                // duration={5}
              />
              <h4 className="text-white text-base md:text-2xl">SERVICE</h4>
            </div>
          </div>

          {/* <a href="/#about">
            <Button variant="outline" className="mt-10">
              LEARN MORE
            </Button>
          </a> */}
        </div>
      </div>
    </div>
  );
};
