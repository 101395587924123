/* eslint-disable no-unused-vars */
export const Pumps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M6.59831 15.333C4.57651 15.333 2.93164 16.9779 2.93164 18.9997V22.9997C2.93164 25.0215 4.57651 26.6663 6.59831 26.6663H6.93164V15.333H6.59831Z"
      fill="#0253B3"
    />
    <path
      d="M27.6634 15.333H27.3301V26.6663H27.6634C29.6852 26.6663 31.3301 25.0215 31.3301 22.9997V18.9997C31.3301 16.9779 29.6852 15.333 27.6634 15.333Z"
      fill="#0253B3"
    />
    <path
      d="M10.5983 24.667C8.57651 24.667 6.93164 26.3119 6.93164 28.3337C6.93164 30.3555 8.57651 32.0003 10.5983 32.0003C12.6201 32.0003 14.265 30.3555 14.265 28.3337C14.265 26.3119 12.6201 24.667 10.5983 24.667Z"
      fill="#0253B3"
    />
    <path
      d="M34.3971 10C32.9267 10 31.7305 11.1963 31.7305 12.6667C31.7305 14.1371 32.9267 15.3333 34.3971 15.3333C35.8675 15.3333 37.0638 14.1371 37.0638 12.6667C37.0638 11.1963 35.8675 10 34.3971 10Z"
      fill="#0253B3"
    />
    <path
      d="M34.3967 17.3336C34.0535 17.3336 33.7191 17.2953 33.3967 17.2246V25.6669C33.3967 26.9535 32.35 28.0003 31.0634 28.0003H27.3301V30.0003H31.0634C33.4528 30.0003 35.3967 28.0563 35.3967 25.6669V17.2246C35.0743 17.2953 34.74 17.3336 34.3967 17.3336Z"
      fill="#0253B3"
    />
    <path
      d="M23.1315 8C20.5582 8 18.4648 10.0934 18.4648 12.6667C18.4648 15.2399 20.5582 17.3333 23.1315 17.3333C25.7048 17.3333 27.7982 15.2399 27.7982 12.6667C27.7982 10.0934 25.7048 8 23.1315 8ZM22.3578 13.4404L20.9578 12.0404L22.372 10.6262L23.772 12.0262L22.3578 13.4404Z"
      fill="#0253B3"
    />
    <path
      d="M20.1133 28.6665L21.7799 31.9998H25.3313V28.6665H20.1133Z"
      fill="#0253B3"
    />
    <path
      d="M23.1342 18.666C20.7832 18.666 18.744 17.3065 17.7605 15.3327H12.4009V13.9993C12.4009 13.8155 12.5504 13.666 12.7342 13.666H17.218C17.1632 13.3407 17.1342 13.0067 17.1342 12.666C17.1342 12.3253 17.1632 11.9913 17.218 11.666H12.7342C11.4476 11.666 10.4009 12.7127 10.4009 13.9993V15.3327H8.26758V23.1708C8.97971 22.8476 9.76931 22.666 10.6009 22.666C13.1457 22.666 15.3036 24.3523 16.0171 26.666H26.0009V17.9357C25.1486 18.4012 24.1718 18.666 23.1342 18.666Z"
      fill="#0253B3"
    />
  </svg>
);
