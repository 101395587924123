/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import contactFormBack from "../../assets/img/contactFormBack.png";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import axios from "axios";

export const ContactForm = () => {
  const formRef = useRef();

  const [form, setForm] = useState();
  const [errors, setErrors] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (form?.name.trim() === "" || form?.name.trim() === undefined) {
      validationErrors.name = "Name is required";
    }
    if (form?.name.trim().length < 2) {
      validationErrors.name = "At least 3 characters";
    }
    // if (form?.email?.trim() === "" || form?.email?.trim() === undefined) {
    //   validationErrors.email = "Email is required";
    // }
    if (form?.email.trim().length < 2) {
      validationErrors.email = "At least 3 characters";
    }
    if (form?.phone.trim().length < 2 || form?.phone.trim() === undefined) {
      validationErrors.phone = "At least 3 characters";
    }
    if (
      form?.message.trim().length < 10 ||
      form?.message.trim() === undefined
    ) {
      validationErrors.message = "At least 10 characters";
    }

    if (!/\S+@\S+\.\S+/.test(form?.email)) {
      validationErrors.email = "Invalid email address";
    }

    // if (!form?.processing || form?.processing === undefined) {
    //   validationErrors.processing = "Required";
    // }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form data
      try {
        setIsLoading(true);
        emailjs
          .sendForm(
            "service_79u0lwr",
            "template_bg33nel",
            formRef.current,
            "s_3YkdA1x1g7j42iQ"
          )
          .then(
            (result) => {
              setIsSent(true);
              setIsLoading(false);
            },
            (error) => {
              console.log(error.text);
              setIsLoading(false);
            }
          );
        axios.post(
          "https://rest.gohighlevel.com/v1/contacts/",
          {
            name: form?.name,
            email: form?.email,
            phone: form?.phone,
            customField: { "7zjv6oBx7OcLWtAGGBCx": form?.message },
          },
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IkV4MlhLQTl1SHpnQ1ZIbmhNMlMwIiwiY29tcGFueV9pZCI6ImdLcEdNRHFOSDVTeUVyWjRMajhVIiwidmVyc2lvbiI6MSwiaWF0IjoxNjkwOTA4ODA0MTQ3LCJzdWIiOiJ1c2VyX2lkIn0.WeJYty7cZiNLsxFmNBSZahx5J3KHqx5-tvDYudJxZBw`,
            },
          }
        );
        // Reset the form
        setForm(null);
        setErrors({});
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="flex h-[700px] md:h-[950px]" id="contactForm">
      <div className="w-1/2 hidden md:block">
        <img src={contactFormBack} className="w-full h-full object-cover" />
      </div>
      <div className="w-full md:w-1/2 p-12 flex flex-col items-center justify-center">
        <div className="flex flex-col">
          {/* {!isSent && !isLoading && (
            <> */}
          {!isSent && !isLoading && (
            <>
              <h2 className="text-primary font-bold text-4xl">SCHEDULE NOW!</h2>
              <p className="font-light mt-1 mb-8">
                Leave your contact information and we'll get back to you.
              </p>
            </>
          )}
          <form ref={formRef}>
            {isLoading && <Spinner />}

            {isSent && (
              <div className="text-primary font-bold text-4xl mt-8">
                Thank you for contacting us, <br /> we will be in touch with you
                shortly
              </div>
            )}
            {!isSent && !isLoading && (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  className="w-full bg-grey-2 text-lg px-4 py-4 outline-none focus:outline-primary my-3"
                  onChange={handleChange}
                  value={form?.name || ""}
                />
                {errors.name && (
                  <span className="text-red-1 text-xs">{errors.name}</span>
                )}

                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full bg-grey-2 text-lg px-4 py-4 outline-none focus:outline-primary my-3"
                  onChange={handleChange}
                  value={form?.email || ""}
                />
                {errors.email && (
                  <span className="text-red-1 text-xs">{errors.email}</span>
                )}
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  className="w-full bg-grey-2 text-lg px-4 py-4 outline-none focus:outline-primary my-3"
                  onChange={handleChange}
                  value={form?.phone || ""}
                />
                {errors.phone && (
                  <span className="text-red-1 text-xs">{errors.phone}</span>
                )}
                <textarea
                  cols={4}
                  name="message"
                  placeholder="Message"
                  className="w-full bg-grey-2 text-lg px-4 py-4 outline-none focus:outline-primary my-3"
                  onChange={handleChange}
                  value={form?.message || ""}
                />
                {errors.message && (
                  <span className="text-red-1 text-xs">{errors.message}</span>
                )}
                {/* <div className="relative flex items-start mt-2">
                  <div className="flex h-6 items-center">
                    <input
                      id="processing"
                      name="processing"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={handleChange}
                      value={form?.processing}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900"
                    >
                      Consent to personal data processing
                    </label>
                  </div>
                  {errors.processing && (
                    <span className="text-red-1 text-xs mt-1 ml-4">
                      {errors.processing}
                    </span>
                  )}
                </div> */}

                <div className="mt-4 font-light">
                  Vital Plumbing may contact me about its services through
                  various automated and recorded means including telephone,
                  text, and email.
                </div>

                <Button
                  variant="movingBlue"
                  className="mt-8"
                  onClick={onSubmit}
                >
                  BOOK APPOINTMENT
                </Button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
