/* eslint-disable no-unused-vars */
export const DrySystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M11.2033 14.4691L6 9.26704L7.8229 7.44414L33.3293 32.9518L31.5064 34.7734L27.5927 30.8571C25.3956 32.9094 22.4873 34.028 19.4812 33.9772C16.4752 33.9261 13.6065 32.7095 11.4804 30.5839C9.3542 28.4582 8.13688 25.5898 8.08519 22.5837C8.0335 19.5777 9.15148 16.6691 11.2033 14.4716V14.4691ZM14.8246 10.8413L19.6647 6L27.8632 14.1985C29.4441 15.7792 30.5345 17.7831 31.0031 19.9691C31.4716 22.155 31.2983 24.4297 30.5041 26.5195L14.8259 10.8413H14.8246Z"
      fill="#0253B3"
    />
  </svg>
);
