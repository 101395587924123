/* eslint-disable no-unused-vars */
export const DelugeSystems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46094 5.98389H37.5831V8.88809H2.46094V5.98389Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46094 31.2314H37.5831V34.1357H2.46094V31.2314Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33398 9.82471H12.3918V12.8745H4.33398V9.82471Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36215 18.8421C7.07529 18.8421 6.0332 17.799 6.0332 16.5132V15.5444H10.6911V16.5132C10.6911 17.799 9.649 18.8421 8.36215 18.8421Z"
      fill="#0253B3"
    />
    <path
      d="M9.14361 20.8828C9.14361 20.4522 8.79453 20.1021 8.36282 20.1021C7.93215 20.1021 7.58203 20.4511 7.58203 20.8828V22.3907C7.58203 22.8214 7.93111 23.1715 8.36282 23.1715C8.79349 23.1715 9.14361 22.8224 9.14361 22.3907V20.8828Z"
      fill="#0253B3"
    />
    <path
      d="M11.524 19.25C11.2193 18.9453 10.7246 18.9453 10.4199 19.25C10.1152 19.5547 10.1152 20.0494 10.4199 20.354L11.4868 21.4209C11.7915 21.7256 12.2862 21.7256 12.5908 21.4209C12.8955 21.1162 12.8955 20.6215 12.5908 20.3169L11.524 19.25Z"
      fill="#0253B3"
    />
    <path
      d="M6.30568 20.354C6.61036 20.0494 6.61036 19.5547 6.30568 19.25C6.00101 18.9453 5.5063 18.9453 5.20163 19.25L4.13476 20.3169C3.83008 20.6215 3.83008 21.1162 4.13476 21.4209C4.43943 21.7256 4.93414 21.7256 5.23881 21.4209L6.30568 20.354Z"
      fill="#0253B3"
    />
    <path
      d="M7.58203 15.5443C7.58203 15.975 7.93111 16.3251 8.36282 16.3251C8.79349 16.3251 9.14361 15.976 9.14361 15.5443V12.8745C9.14361 12.4439 8.79453 12.0938 8.36282 12.0938C7.93215 12.0938 7.58203 12.4428 7.58203 12.8745V15.5443Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9941 9.82471H24.052V12.8745H15.9941V9.82471Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0223 18.8421C18.7354 18.8421 17.6934 17.799 17.6934 16.5132V15.5444H22.3512V16.5132C22.3523 17.799 21.3092 18.8421 20.0223 18.8421Z"
      fill="#0253B3"
    />
    <path
      d="M20.8018 20.8828C20.8018 20.4522 20.4527 20.1021 20.021 20.1021C19.5893 20.1021 19.2402 20.4511 19.2402 20.8828V22.3907C19.2402 22.8214 19.5893 23.1715 20.021 23.1715C20.4527 23.1715 20.8018 22.8224 20.8018 22.3907V20.8828Z"
      fill="#0253B3"
    />
    <path
      d="M23.1822 19.25C22.8775 18.9453 22.3828 18.9453 22.0781 19.25C21.7734 19.5547 21.7734 20.0494 22.0781 20.354L23.145 21.4199C23.4497 21.7246 23.9444 21.7246 24.249 21.4199C24.5537 21.1152 24.5537 20.6205 24.249 20.3158L23.1822 19.25Z"
      fill="#0253B3"
    />
    <path
      d="M17.9658 20.354C18.2705 20.0494 18.2705 19.5547 17.9658 19.25C17.6612 18.9453 17.1665 18.9453 16.8618 19.25L15.7949 20.3169C15.4902 20.6215 15.4902 21.1162 15.7949 21.4209C16.0996 21.7256 16.5943 21.7256 16.899 21.4209L17.9658 20.354Z"
      fill="#0253B3"
    />
    <path
      d="M19.2422 15.5443C19.2422 15.975 19.5913 16.3251 20.023 16.3251C20.4547 16.3251 20.8038 15.976 20.8038 15.5443V12.8745C20.8038 12.4439 20.4547 12.0938 20.023 12.0938C19.5913 12.0938 19.2422 12.4428 19.2422 12.8745V15.5443Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6523 9.82471H35.7102V12.8745H27.6523V9.82471Z"
      fill="#0253B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6825 18.8421C30.3956 18.8421 29.3535 17.799 29.3535 16.5132V15.5444H34.0114V16.5132C34.0114 17.799 32.9683 18.8421 31.6825 18.8421Z"
      fill="#0253B3"
    />
    <path
      d="M32.462 20.8828C32.462 20.4522 32.1129 20.1021 31.6812 20.1021C31.2505 20.1021 30.9004 20.4511 30.9004 20.8828V22.3907C30.9004 22.8214 31.2495 23.1715 31.6812 23.1715C32.1119 23.1715 32.462 22.8224 32.462 22.3907V20.8828Z"
      fill="#0253B3"
    />
    <path
      d="M34.8423 19.25C34.5376 18.9453 34.0429 18.9453 33.7383 19.25C33.4336 19.5547 33.4336 20.0494 33.7383 20.354L34.8051 21.4209C35.1098 21.7256 35.6045 21.7256 35.9092 21.4209C36.2139 21.1162 36.2139 20.6215 35.9092 20.3169L34.8423 19.25Z"
      fill="#0253B3"
    />
    <path
      d="M29.624 20.354C29.9287 20.0494 29.9287 19.5547 29.624 19.25C29.3194 18.9453 28.8247 18.9453 28.52 19.25L27.4531 20.3169C27.1484 20.6215 27.1484 21.1162 27.4531 21.4209C27.7578 21.7256 28.2525 21.7256 28.5572 21.4209L29.624 20.354Z"
      fill="#0253B3"
    />
    <path
      d="M30.9004 15.5443C30.9004 15.975 31.2495 16.3251 31.6812 16.3251C32.1119 16.3251 32.462 15.976 32.462 15.5443V12.8745C32.462 12.4439 32.1129 12.0938 31.6812 12.0938C31.2505 12.0938 30.9004 12.4428 30.9004 12.8745V15.5443Z"
      fill="#0253B3"
    />
  </svg>
);
