/* eslint-disable no-unused-vars */
export const Waterless = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M36.4992 10.7799V5.99561H3.5V10.7799H19.0328V15H16.9818V17H23L23.0078 15H20.9568L20.9664 10.7799H36.4992Z"
      fill="#0253B3"
    />
    <path
      d="M16.5302 24.9537L14.3965 22.7024L15.144 21.9136L25.6035 32.9525L24.856 33.7408L23.2511 32.046C22.3501 32.9342 21.1575 33.4182 19.9248 33.3962C18.6921 33.3741 17.5157 32.8476 16.6438 31.9277C15.772 31.0078 15.2728 29.7664 15.2516 28.4655C15.2304 27.1646 15.6888 25.9058 16.5302 24.9548V24.9537ZM18.0152 23.3837L20 21.2886L23.362 24.8366C24.0103 25.5207 24.4575 26.3879 24.6496 27.334C24.8417 28.28 24.7707 29.2644 24.445 30.1688L18.0158 23.3837H18.0152Z"
      fill="#0253B3"
    />
  </svg>
);
