export const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#5DC5F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.5 15.989V13.73c.009-.37-.118-.73-.358-1.011-.674-.795-2.987-1.303-3.904-1.107-.715.152-1.215.87-1.704 1.357a12.056 12.056 0 0 1-4.526-4.516c.49-.488 1.209-.987 1.361-1.7.195-.914-.312-3.213-1.101-3.89a1.51 1.51 0 0 0-.999-.364H4.007c-.862 0-1.58.774-1.501 1.641C2.5 11.613 8.333 17.5 15.856 17.494c.87.079 1.647-.642 1.644-1.505Z"
    />
  </svg>
);
