/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
import ServiceBack from "../../assets/img/Service-banner.png";
import { Card } from "../../components/Card";
import { PlumbingIcon } from "../../icons/plumbingIcon";
import { SprinklersIcon } from "../../icons/sprinklersIcon";
import { useNavigate } from "react-router-dom";
import { AirConditionWhiteIcon } from "../../icons/airConditionerWhiteIcon";
import { ViolationsWhiteIcon } from "../../icons/violationsWhiteIcon";
import { Helmet } from "react-helmet-async";

export const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Services for commercial property in NYC</title>
        <meta
          name="description"
          content="Services for commercial property, businesses and industrial facilities in New York. Call us for a quick plumbing solutions."
        />
      </Helmet>
      <img src={ServiceBack} alt="Vital Service" className="w-full" />
      <div className="bg-[#F9FAFB]">
        <div className="mx-auto max-w-7xl px-5 py-28 bg-[#F9FAFB]">
          <div className="flex flex-col justify-center gap-8">
            <h1 className="text-primary font-bold text-4xl w-full  text-center">
              OUR SERVICES
            </h1>
            <div className="mx-auto max-w-7xl px-5 -mt-10 relative">
              <div className="flex flex-wrap items-stretch gap-8 flex-col md:flex-row my-12">
                <Card
                  className="bg-white flex items-center flex-col py-12 px-4 shadow-card-white cursor-pointer"
                  onClick={() => navigate("/plumbing")}
                >
                  <PlumbingIcon />
                  <h2 className="text-2xl font-bold text-center text-blue-1 mt-2">
                    VITAL PLUMBING <br /> SERVICES
                  </h2>
                </Card>
                <Card
                  className="bg-white flex items-center flex-col py-12 px-4 shadow-card-white cursor-pointer"
                  onClick={() => navigate("/fire-protection")}
                >
                  <SprinklersIcon />
                  <h2 className="text-2xl font-bold text-center text-red-1  mt-2">
                    VITAL FIRE <br /> PROTECTION
                  </h2>
                </Card>
                <Card
                  className="bg-white  flex items-center flex-col py-12 px-4 shadow-card-white cursor-pointer"
                  onClick={() => navigate("/heating-cooling-service")}
                >
                  <AirConditionWhiteIcon />
                  <h2 className="text-2xl font-bold text-center text-[#F5B000] mt-2">
                    VITAL HEATING <br /> AND COOLING
                  </h2>
                </Card>
                <Card
                  className="bg-white  flex items-center flex-col py-12 px-4 shadow-card-white cursor-pointer"
                  onClick={() => navigate("/inspection")}
                >
                  <ViolationsWhiteIcon />
                  <h2 className="text-2xl font-bold text-center text-primary  mt-2">
                    VITAL VIOLATIONS <br /> & INSPECTIONS
                  </h2>
                </Card>
              </div>
              <p className="text-xl text-blue-1 mt-16">
                Plumbing, Fire Protection, HVAC and Violations form four
                divisions of our company. Each division can work independently
                or collaborate with the others on a wide range of projects,
                spanning from minor repairs to large-scale new construction
                ventures. We prioritize the implementation of preventive
                maintenance plans for the buildings and facilities we service.
                This approach ensures that equipment enjoys a longer lifespan,
                operating costs are minimized, and the risk of costly failures
                is mitigated. Whether you engage any of Vital’s divisions for
                your project, you can expect dependable and expert outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};
